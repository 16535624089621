import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Modal, notification, Form, Input, message } from "antd";
import axios from "axios";

import { getLocalUserId } from "../../services/auth-services";
import UserComponent from "../../components/auth/user-info";
import UserDeleteComponent from "../../components/auth/user-delete";
import PageHeader from "../../layout/header";
import SectionImage from "../../components/visual/section-image";

//import PageFooter from "../../layout/footer";

function Config() {
  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  let navigate = useNavigate();
  const [form] = Form.useForm();
  /*
  useEffect(() => {}, []);

  const back = () => {
    const isDirectAccess =
      window.performance.getEntriesByType("navigation")[0].type === "navigate";

    if (isDirectAccess) {
      navigate("/");
    } else {
      navigate(-1);
    }
  };
  */
  const onClickClear = () => {
    localStorage.clear();
    notification.success({
      message: "Datos borrados correctamente",
    });
    navigate("/");
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  /*
  const handleSubmitPassword = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosAuth.post(
        "/api/change-password/",
        {
          old_password: oldPassword,
          new_password: newPassword,
        },
      );
      if (response.status === 204) {
        setSuccess(true);
      }
    } catch (error) {
      if (error.response) {
        setError(error.response.data);
      } else {
        setError({
          non_field_errors: [
            "Ocurrió un error al intentar cambiar la contraseña.",
          ],
        });
      }
    }
  };
 */
  const onFinish = (values) => {
    setLoading(true);
    axios
      .post("/api/change-password", values)
      .then(() => {
        message.success("Contraseña actualizada con éxito");
        form.resetFields();
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          message.error("Error en la solicitud");
          form.setFields([
            {
              name: "old_password",
              errors: error.response.data.old_password,
            },
          ]);
        } else {
          message.error("Error al actualizar la contraseña");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <PageHeader />
      <SectionImage title={"Tu cuenta"} />
      <div>
        <div className="col-md-8 offset-md-2 col-xs-12 mt-4 text-page">
          <div>
            <UserComponent userId={getLocalUserId()} />
            {/* 
                <Button
                  onClick={() => {
                    setModalVisible(true);
                  }}
                >
                  Cambiar contraseña
                </Button>
               
            <Button onClick={onClickClear} className="d-none">
              Limpiar datos de navegación
            </Button>
             */}
            <div className="text-center my-4">
              <UserDeleteComponent userId={getLocalUserId()} />
              <br />
            </div>
          </div>
        </div>
      </div>

      <Modal open={modalVisible} onCancel={closeModal} footer={null}>
        <h5>Cambio de contraseña</h5>
        <Form
          form={form}
          name="changePassword"
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            name="old_password"
            label="Contraseña actual"
            rules={[
              {
                required: true,
                message: "Por favor, escribe tu contraseña actual",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            name="new_password"
            label="Nueva Contraseña"
            rules={[
              {
                required: true,
                message: "Por favor, escribe tu nueva contraseña",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading}>
              Cambiar
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default Config;
