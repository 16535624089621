import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { List, Card } from "antd";
import slugify from "react-slugify";

import FavoriteButton from "../../components/visual/fav-pos-center-button";
import PageHeader from "../../layout/header";
import PageFooter from "../../layout/footer";
import SectionImage from "../../components/visual/section-image";

import axiosAuth from "../../components/utils/auth-interceptor";
import { API_CENTER_URL, trailing_key } from "../../constants";
import { getChunkByKey } from "../../services/utils-services";
import { renderAsP } from "../portal/chunk-page";

function PositionsFavPage() {
  const [positions, setPositions] = useState([]);
  const [text, setText] = useState("");

  useEffect(() => {
    document.title =
      "Destino Docente. Lista de centros de secundaria y primaria de España por comunidades autónomas";

    const fetchFavs = () => {
      // user en request
      const uri = `${API_CENTER_URL}/positions/favcenterpositions/user-favorites/${trailing_key}`;
      axiosAuth
        .get(uri)
        .then((response) => {
          if (response.data) {
            setPositions(response.data);
          }
        })
        .catch((error) => {
          console.error("Error al obtener favoritos", error);
        });
    };

    const fetchDataChunk = async (chunk) => {
      try {
        const res = await getChunkByKey(chunk);
        setText(res.content);
      } catch (error) {
        console.log("Error fetching chunk");
      }
    };

    fetchFavs();
    fetchDataChunk("Favlist");
  }, []);

  const renderItem = (item) => {
    return (
      <List.Item key={item.id}>
        <Card
          style={{ width: "100%" }}
          actions={[
            <FavoriteButton
              centerCode={item.center.code}
              positionCode={item.position.code}
              userId={item.user.id}
              className=" "
            >
              Favorito
            </FavoriteButton>,
          ]}
        >
          <b>
            {item.position.code} {item.position.name}
            <Link
              to={`/centros/${item.center.code}/${slugify(item.center.name)}`}
            >
              {" "}
              {item.center.name}
            </Link>
          </b>
        </Card>
      </List.Item>
    );
  };

  return (
    <>
      <PageHeader />
      <div className="row mb-4">
        <SectionImage title={"Tus Puestos"} />
        <div className="col-md-8 offset-md-2 col-xs-12 text-page">
          {renderAsP(text)}
          <List
            dataSource={positions}
            renderItem={renderItem}
            locale={{ emptyText: "No hay puestos guardados" }}
          />
        </div>
      </div>
      <PageFooter />
    </>
  );
}

export default PositionsFavPage;
