import React, { useState } from "react";
import { Button, Popup, Popover, Card } from "antd-mobile";
import { isMobile } from "react-device-detect";
import { ShareAltOutlined } from "@ant-design/icons";

const ShareMenu = ({ children, title }) => {
  const [visible, setVisible] = useState(false);

  const toggleMenu = () => {
    setVisible(!visible);
  };

  return (
    <div>
      {isMobile ? (
        <Button
          fill="none"
          shape="circle"
          onClick={toggleMenu}
          style={{ border: "none", padding: 0 }}
        >
          <ShareAltOutlined />
        </Button>
      ) : (
        <Popover
          content={children}
          visible={visible}
          onVisibleChange={setVisible}
          trigger="click"
          placement="bottom-end"
        >
          <Button
            fill="none"
            shape="circle"
            style={{ border: "none", padding: 0 }}
          >
            <ShareAltOutlined />
          </Button>
        </Popover>
      )}
      {isMobile && (
        <Popup
          visible={visible}
          onMaskClick={toggleMenu}
          position="bottom"
          bodyStyle={{ padding: "16px 8px" }}
        >
          <h5>{!!title ? title : "Comparte"}</h5>
          {children}
        </Popup>
      )}
    </div>
  );
};

export default ShareMenu;
