import { useNavigate } from "react-router-dom";
import { Button, Tooltip } from "antd";
import { isAuth } from "../../services/auth-services";

function LoginButton(props) {
  const navigate = useNavigate();

  const gotoLogin = () => {
    if (!isAuth()) {
      const loginPath = "/entrar";
      const stateObj = { state: { from: props.from } };
      if (props.target === "_blank") {
        const fullUrl = `${
          window.location.origin
        }${loginPath}?from=${encodeURIComponent(props.from)}`;
        window.open(fullUrl, "_blank");
      } else {
        navigate(loginPath, stateObj);
      }
    } else {
      if (props.target === "_blank") {
        const fullUrl = `${window.location.origin}${props.from}`;
        window.open(fullUrl, "_blank");
      } else {
        navigate(props.from);
      }
    }
  };

  return (
    <Tooltip title={props.title}>
      <Button
        //title={props.title}
        type="button"
        onClick={gotoLogin}
        className={props.className ? props.className : "p-0"}
        style={{ backgroundColor: props.color }}
      >
        {!!props.children && <>{props.children}</>}
      </Button>
    </Tooltip>
  );
}

export default LoginButton;
