import React, { useState, useEffect } from "react";
import axios from "axios";
import { Form, Button, Row, Col, Space, Radio } from "antd";
import { FaCar, FaSchool, FaUniversity, FaWalking } from "react-icons/fa";
import MunicipalityAutocomplete from "../municipality-autocomplete";
import { getMunByCode } from "../../services/utils-services";
import {
  API_TRAVELS_URL,
  CEIP_DENOM,
  IES_DENOM,
  api_key,
} from "../../constants";
import { buildQueryString } from "../../utils/utils";

const TravelSearchForm = (props) => {
  const [form] = Form.useForm();
  const [munCode, setMunCode] = useState(null);
  const [munCodeDest, setMunCodeDest] = useState(null);
  const [schedule, setSchedule] = useState(null);
  const [travelType, setTravelType] = useState(null);
  const [loading, setLoading] = useState(false);

  const getResults = async () => {
    const params = [
      { key: "muncode", value: munCode },
      { key: "muncodedest", value: munCodeDest },
      { key: "schedule", value: schedule },
      { key: "travelType", value: travelType },
      { key: "api_key", value: api_key },
    ];

    const queryString = buildQueryString(params);
    const uri = `${API_TRAVELS_URL}/search-travels/?${queryString}`;
    try {
      const response = await axios.get(uri);
      if (response.data) {
        props.setTravels(response.data);
      }
    } catch (error) {
      console.error("Hubo un error recuperar los datos", error);
    }
  };

  useEffect(() => {
    getResults();
  }, [munCode, munCodeDest, schedule, travelType]);

  const onFinish = async (e) => {
    setLoading(true);
    await getResults();
    setLoading(false);
  };

  const onChangeSchedule = (e) => {
    //  setSchedule(value);
    setSchedule(e.target.value);
  };

  const onChangeTravel = (e) => {
    //  setTravelType(value);
    setTravelType(e.target.value);
  };

  const onSelectMunicipalities = async (value, item) => {
    if (value !== undefined) {
      const children = item.label.props.children;
      let coordinates = children[1].props.children;
      const code = children[2].props.children;
      try {
        const data = await getMunByCode(code);
        if (data) {
          coordinates = [data.longitude, data.latitude];
        }
      } catch (error) {
        console.error("Error:", error.message);
      }
      //setCoords([coordinates[1], coordinates[0]]);
      setMunCode(code); // munCode
    }
  };

  const onSelectMunicipalitiesDest = async (value, item) => {
    if (value !== undefined) {
      const children = item.label.props.children;
      let coordinates = children[1].props.children;
      const code = children[2].props.children;
      try {
        const data = await getMunByCode(code);
        if (data) {
          coordinates = [data.longitude, data.latitude];
        }
      } catch (error) {
        console.error("Error:", error.message);
      }
      //setCoords([coordinates[1], coordinates[0]]);
      setMunCodeDest(code); // munCode
    }
  };

  const onClearMunicipalities = () => {
    setMunCode(null);
    form.setFieldsValue({ mun_code: null });
  };

  const onClearMunDest = () => {
    setMunCodeDest(null);
    form.setFieldsValue({ mun_code_dest: null });
  };

  return (
    <Form form={form} onFinish={onFinish}>
      <Row gutter={[4, 4]}>
        <Col md={24} xs={24}>
          <Form.Item name="mun_code">
            <MunicipalityAutocomplete
              autoFocus={false}
              onSelectMunicipalities={onSelectMunicipalities}
              onClearMunicipalities={onClearMunicipalities}
              placeHolder="Municipio de origen"
              fullOnclick={true}
              size="large"
              iconSuffix="De"
            />
          </Form.Item>
        </Col>
        <Col md={24} xs={24}>
          <Form.Item name="mun_code_dest">
            <MunicipalityAutocomplete
              autoFocus={false}
              onSelectMunicipalities={onSelectMunicipalitiesDest}
              onClearMunicipalities={onClearMunDest}
              placeHolder="Municipio de destino"
              fullOnclick={true}
              size="large"
              iconSuffix="A"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[4, 4]}>
        <Col md={24} xs={24}>
          <Form.Item name="schedule" className="my-3">
            <Radio.Group
              onChange={onChangeSchedule}
              style={{ width: "100%" }}
              className="radio-button"
            >
              <div style={{ display: "flex", width: "100%" }}>
                <Radio.Button
                  style={{ flex: 1, textAlign: "center" }}
                  value={CEIP_DENOM}
                >
                  <FaSchool style={{ color: "LimeGreen" }} /> Horario CEIP
                </Radio.Button>
                <Radio.Button
                  style={{ flex: 1, textAlign: "center" }}
                  value={IES_DENOM}
                >
                  <FaUniversity style={{ color: "darkred" }} /> Horario IES
                </Radio.Button>
              </div>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col md={24} xs={24}>
          <hr
            className="mt-1"
            style={{
              border: "2px solid #7ebf8e",
              width: "100%",
              height: "1px",
            }}
          />
          <Form.Item name={"travel"}>
            <Radio.Group
              onChange={onChangeTravel}
              style={{ width: "100%" }}
              className="radio-button"
            >
              <div style={{ display: "flex", width: "100%" }}>
                <Radio.Button
                  style={{ flex: 1, textAlign: "center" }}
                  value={"C"}
                >
                  <FaCar style={{ color: "LimeGreen" }} />
                  Tengo coche
                </Radio.Button>
                <Radio.Button
                  style={{ flex: 1, textAlign: "center" }}
                  value={"N"}
                >
                  <FaWalking style={{ color: "darkred" }} />
                  No tengo coche
                </Radio.Button>
              </div>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[8, 8]} className="d-none">
        <Col md={24} xs={24}>
          <Form.Item className="mb-0">
            <Button type="primary" htmlType="submit" loading={loading} block>
              Buscar
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default TravelSearchForm;
