import React, { useState, useEffect } from "react";
import { Tooltip, Badge, Button } from "antd";
import { FaWhatsapp } from "react-icons/fa";
import { capitalize, pluralize, shareWhatsApp } from "../utils/utils";
import { getItemsStartingWith } from "../services/storage-service";

import "bootstrap/dist/css/bootstrap.min.css";

const CenterPositions = (props) => {
  const [positions, setPositions] = useState([]);
  const [checkCounter, setCheckCounter] = useState(0);

  useEffect(() => {
    const checkLocalStorage = () => {
      const positions_ = getItemsStartingWith(`latest-pos-${props.idCenter}-`);
      if (positions_) {
        setPositions(positions_);
      } else {
        setCheckCounter(checkCounter + 1);
        setTimeout(checkLocalStorage, 2000);
      }
    };

    if (checkCounter < 3) {
      checkLocalStorage();
    }
  }, [props.idCenter, checkCounter]);

  useEffect(() => {
    // para las búsquedas
    if (props.addPositionsToMarker && Object.keys(positions).length !== 0) {
      props.addPositionsToMarker(props.marker, positions);
    }
  }, [props.marker, positions]);

  return (
    <>
      {positions && Object.keys(positions).length > 0 && (
        <>
          <ul
            style={{
              listStyle: "none",
              display: "flex",
              paddingLeft: 0,
              marginBottom: 0,
            }}
          >
            <li>
              <Tooltip title="Puestos INTERINOS disponibles">
                <Badge
                  count={pluralize(Object.keys(positions).length, "puesto")}
                  showZero
                  color="#8C5659"
                />
                {"     "}
              </Tooltip>
            </li>
            {Object.keys(positions).map((key) => (
              <li key={key} className="px-1">
                <Tooltip
                  className="px-1"
                  title={
                    "Actualizado " +
                    new Date(positions[key]?.date).toLocaleDateString("es-es", {
                      year: "numeric",
                      month: "numeric",
                      day: "numeric",
                    })
                  }
                >
                  {capitalize(positions[key]?.position?.split("-")[1])}
                </Tooltip>
              </li>
            ))}
            <li className="d-none">
              <Button
                style={{ padding: 0, width: "100%", height: "100%", color: "green", marginTop: "-5px", fontWeight: "500" }}
                type="button"
                onClick={() =>
                  shareWhatsApp(
                    "Hay " +
                      pluralize(Object.keys(positions).length, " puesto") +
                      ` en ${props.marker?.denom__name} "${props.marker?.name}" de ${props.marker.city}. ` +
                      ` https://destinodocente.es/centros/${props.marker?.code}`
                  )
                }
              >
                <FaWhatsapp />
              </Button>
            </li>
          </ul>
          <style type="text/css">   
            {"#marker_" + props.idCenter + " { text-shadow: 0 0 2px #26400C; }"}
          </style>
        </>
      )}
    </>
  );
};

export default CenterPositions;
