import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import RoutesCenters from "./routes";
import Bugsnag from "@bugsnag/js";
import CookieConsent from "react-cookie-consent";
import { ConfigProvider } from "antd";
import esES from "antd/lib/locale/es_ES";

import App from "./App";
import "./index.css";

const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <RoutesCenters />
      <ConfigProvider locale={esES}>
        <App />
      </ConfigProvider>
      <CookieConsent
        enableDeclineButton
        location="bottom"
        buttonText="Acepto"
        declineButtonText="NO acepto"
        cookieName="cookieConsent"
        style={{ background: "#2B373B" }}
        buttonStyle={{ backgroundColor: "#EBF2EE", fontSize: "14px" }}
        expires={150}
      >
        Utilizamos cookies para mejorar tu experiencia en nuestro sitio web.
        Pulsa "Aceptar" para continuar navegando.
      </CookieConsent>
    </ErrorBoundary>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
