import React, { useState, useEffect } from "react";
import { FaBookmark, FaRegBookmark } from "react-icons/fa";
import { Button } from "antd";
import LoginButton from "../auth/login-button";
import axiosAuth from "../utils/auth-interceptor";
import {
  API_CENTER_URL,
  trailing_key_param,
  trailing_key,
} from "../../constants";
import { isAuth } from "../../services/auth-services";

/**
 * Botón favorito para un puesto y centro
 * @param {*} props
 * @returns
 */
const FavoriteButton = (props) => {
  const [isFavorite, setIsFavorite] = useState(false);
  const [favCenterPositionId, setFavCenterPositionId] = useState(null);

  useEffect(() => {
    const uri = `${API_CENTER_URL}/positions/favcenterpositions/retrieve-by-params/?centercode=${props.centerCode}&positioncode=${props.positionCode}&userid=${props.userId}${trailing_key_param}`;
    axiosAuth
      .get(uri)
      .then((response) => {
        if (response.data) {
          setIsFavorite(true);
          setFavCenterPositionId(response.data.id); // Asumiendo que la API devuelve un arreglo de objetos
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          console.error("Error 404: No encontrado.");
        } else {
          console.error("Error al verificar el favorito", error);
        }
      });
  }, [props.centerCode, props.positionCode, props.userId]);

  const toggleFavorite = () => {
    if (isFavorite) {
      // Eliminar el favorito
      const uri = `${API_CENTER_URL}/positions/favcenterpositions/${favCenterPositionId}/${trailing_key}`;
      axiosAuth
        .delete(uri)
        .then(() => {
          setIsFavorite(false);
          setFavCenterPositionId(null);
        })
        .catch((error) =>
          console.error("Error al eliminar el favorito", error)
        );
    } else {
      const params = {
        centercode: props.centerCode,
        positioncode: props.positionCode,
        userid: props.userId,
      };
      const uri2 = `${API_CENTER_URL}/positions/favcenterpositions/create-by-params/${trailing_key}`;
      // Crear un nuevo favorito
      axiosAuth
        .post(uri2, params)
        .then((response) => {
          setIsFavorite(true);
          setFavCenterPositionId(response.data.id); // Asumiendo que la respuesta incluye el id del favorito creado
        })
        .catch((error) => console.error("Error al crear el favorito", error));
    }
  };

  return (
    <>
      {!!isAuth() ? (
        <Button
          onClick={toggleFavorite}
          className={props.className ? props.className : "no-border"}
        >
          {isFavorite ? (
            <FaBookmark color="gold" size={16} className="text-shadow" />
          ) : (
            <FaRegBookmark size={16} className="text-shadow" />
          )}
          {!!props.children && <span className="ms-2">{props.children}</span>}
        </Button>
      ) : (
        <>
          <LoginButton
            from={window.location.pathname}
            style={{ border: "none", background: "none" }}
          >
            <FaRegBookmark size={16} className="text-shadow" />
            {!!props.children && <span className="me-2">{props.children}</span>}
          </LoginButton>
        </>
      )}
    </>
  );
};

export default FavoriteButton;
