import React from "react";
import { Upload, notification } from "antd";
import { InboxOutlined } from "@ant-design/icons";

const { Dragger } = Upload;
const TYPES_IMG = ["image/png", "image/jpg", "image/jpeg"];
const MAX_COUNT = 2;

const ImageSelector = ({ setFiles, files }) => {
  const props = {
    name: "images",
    action: "",
    maxCount: MAX_COUNT,
    multiple: true, // Cambié a false porque MAX_COUNT es 1
    defaultFileList: files,
    listType: "picture-card",
    beforeUpload: (file) => {
      const isValid = TYPES_IMG.includes(file.type);
      if (!isValid) {
        notification.warning({
          message: `${file.name} no es un formato válido`,
        });
      }

      if (files.length >= MAX_COUNT) {
        notification.warning({
          message: `Sólo puedes subir ${MAX_COUNT} foto${
            MAX_COUNT > 1 ? "s" : ""
          }`,
        });
      }

      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        notification.warning({
          message: "La foto puede ser hasta 2MB",
        });
      }

      return isValid && isLt2M ? false : Upload.LIST_IGNORE;
    },
    onChange: (info) => {
      // Filtrar solo los archivos exitosos
      //const filteredFileList = info.fileList.filter(file => file.status !== "error");
      //setFiles(filteredFileList);
      setFiles(info.fileList);
    },
  };

  return (
    <Dragger {...props}>
      <p className="ant-upload-drag-icon text-center">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text d-none d-sm-block">
        Haz clic o arrastra las imágenes
      </p>
      <p className="ant-upload-text text-center d-block d-sm-none">
        Pulsa para incluir imágenes
      </p>
    </Dragger>
  );
};

export default ImageSelector;
