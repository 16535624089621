import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment/moment";
import { Row, Col, Card } from "antd";
import { NavBar } from "antd-mobile";
import {
  FaCar,
  FaArrowRight,
  FaSchool,
  FaUniversity,
  FaWalking,
  FaCalendarAlt,
  FaRoad,
} from "react-icons/fa";
import slugify from "react-slugify";
import MessageButton from "../../components/messaging/message-button";
import TravelDeleteButton from "../../components/travels/travel-delete-button";
import { ShareWSButton, ShareFBButton } from "../../utils/utils";
import ShareMenu from "../../components/visual/share-menu";
import { API_TRAVELS_URL } from "../../constants";
import { trailing_key } from "../../constants";
import { denomAbrev } from "../../constants/data";
import { getLocalUserId } from "../../services/auth-services";
import { IES_DENOM } from "../../constants";


//const { Meta } = Card;
const iconSize = 20;

const TravelInfo = () => {
  const { travelId: pTravelId, travelName: pTravelName } = useParams();
  const [item, setItem] = useState(null);
  let navigate = useNavigate();

  const fetchData = (id) => {
    const uri = `${API_TRAVELS_URL}/${id}/${trailing_key}`;
    if (uri) {
      axios
        .get(uri)
        .then((response) => {
          if (response.data) {
            setItem(response.data);
          }
        })
        .catch((error) => {
          // Verificar si el error es un error de respuesta HTTP y si el código es 404
          if (error.response && error.response.status === 404) {
            console.error("Error 404: No encontrado.");
          } else {
            console.error("Error al verificar el anuncio", error);
          }
          setItem(null);
        });
    }
  };

  useEffect(() => {
    if (pTravelId) fetchData(pTravelId);
  }, [pTravelId]);

  const afterTravelDelete = () => {};
  
  const back = () => {
    const isDirectAccess =
      window.performance.getEntriesByType("navigation")[0].type === "navigate";

    if (isDirectAccess) {
      navigate("/compartir-coche");
    } else {
      navigate(-1);
    }
  };

  return (
    <>
      <NavBar onBack={back} className="back-bar" style={{ height: "64px" }}>
        {item && (
          <>
            {item?.municipality_from?.name} <FaArrowRight className="mx-2" />{" "}
            {denomAbrev[item.center_to?.denom]} "{item.center_to?.name}" de{" "}
            {item.center_to?.municipality} ({item.center_to?.province})
          </>
        )}
      </NavBar>
      <div className="row">
        <div className="col-md-8 offset-md-2 col-xs-12 text-page">
          {item ? (
            <Card
              className="mt-4"
              style={{ width: "100%" }}
              actions={[
                <>
                  {item?.creator?.id != getLocalUserId() ? (
                    <MessageButton
                      senderId={getLocalUserId()}
                      recipientId={item?.creator?.id}
                    >
                      <>
                        <span className="ms-2">
                          Escribe a {item?.creator?.first_name}
                        </span>
                      </>
                    </MessageButton>
                  ) : (
                    <>
                      <TravelDeleteButton
                        userId={getLocalUserId()}
                        travel={item}
                        afterFinish={() => {
                          afterTravelDelete();
                        }}
                      />
                      {/*<TravelButton travel={item} className="btn-action ms-2" text={<>{"Editar"}</>} />**/}
                    </>
                  )}
                </>,
              ]}
              extra={[
                <ShareMenu title="Comparte este viaje">
                  <ul className="share-menu">
                    <li key={item.id + "-1"} className="py-2">
                      <ShareWSButton
                        color="rgb(0,168,132)"
                        size="28"
                        message={
                          "¡Míralo en en Destino Docente! Viaje de " +
                          `${item?.municipality_from.name} a "${item?.center_to.name}" de ${item?.center_to.municipality}` +
                          ` https://destinodocente.es/compartir-coche/${item?.id}/${slugify(
                            item?.municipality_from.name +
                              " a " +
                              item?.center_to.name
                          )}`
                        }
                      >
                        <span className="ms-2">WhatsApp</span>
                      </ShareWSButton>
                    </li>
                    <li>
                      <ShareFBButton
                        color="#1877f2"
                        size="28"
                        url={`https://destinodocente.es/compartir-coche/${item?.id}/${slugify(
                          item?.municipality_from.name + " a " + item?.center_to.name
                        )}`}
                        message={`¡Míralo en Destino Docente! Viaje de ${item?.municipality_from.name} a ${item?.center_to.name} de ${item?.center_to.municipality}`}
                      >
                        <span className="ms-2">Facebook</span>
                      </ShareFBButton>
                    </li>
                  </ul>
                </ShareMenu>,
              ]}
            >
              <Row>
                <Col md={24}>
                  <h6>
                    {item?.municipality_from?.name}{" "}
                    <FaArrowRight className="mx-2" />{" "}
                    {denomAbrev[item.center_to?.denom]} "{item.center_to?.name}"
                    de {item.center_to?.municipality} (
                    {item.center_to?.province})
                  </h6>
                </Col>
                <Col>
                  <Row gutter={[24, 24]} className="mt-3 col-xs-12">
                    <Col>
                      {item.schedule === IES_DENOM ? (
                        <>
                          <FaUniversity
                            size={iconSize}
                            style={{ color: "darkred" }}
                          />{" "}
                          Instituto
                        </>
                      ) : (
                        <>
                          <FaSchool
                            size={iconSize}
                            style={{ color: "limegreen" }}
                          />{" "}
                          Colegio
                        </>
                      )}
                    </Col>
                    <Col>
                      {item.travel_type === "C" ? (
                        <>
                          <FaCar
                            size={iconSize}
                            style={{ color: "limegreen" }}
                          />{" "}
                          Coche
                        </>
                      ) : (
                        <>
                          <FaWalking
                            size={iconSize}
                            style={{ color: "darkred" }}
                          />{" "}
                          Sin Coche
                        </>
                      )}
                    </Col>
                    <Col>
                      <FaCalendarAlt size={iconSize - 2} />
                      <span className="ms-2">
                        {item?.start_date
                          ? moment(item?.start_date).format("D/M/YY")
                          : ""}{" "}
                        a{" "}
                        {item?.end_date
                          ? moment(item?.end_date).format("D/M/YY")
                          : ""}
                      </span>
                    </Col>
                    <Col>
                      {item?.roadDuration && (
                        <>
                          <FaRoad size={iconSize} /> {item.roadDuration}
                        </>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          ) : (
            <>No encontrado</>
          )}
        </div>
      </div>
    </>
  );
};

export default TravelInfo;
