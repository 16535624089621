import React, { useState, useEffect } from "react";
import axios from "axios";
import { NoticeBar } from "antd-mobile";

import { API_PUSH_URL, trailing_key } from "../../constants";

function Notices() {
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    const fetchNotifications = async () => {
      const uri = API_PUSH_URL + trailing_key;
      await axios
        .get(uri, {
          timeout: 5000, // Establece el timeout
        })
        .then((response) => {
          setNotifications(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    };
    
    fetchNotifications();
  }, []);

  return (
    <>
      {!!notifications && notifications.length > 0 && (
        <div style={{ width: "100%" }}>
          <NoticeBar
            content={
              <>
                {notifications[0]?.title}:{" "}
                <span
                  className="notice"
                  dangerouslySetInnerHTML={{ __html: notifications[0]?.body }}
                />
                {"   "}
                <a href="/noticias" style={{ color: "white" }}>
                  <b>
                    <i className="fa fa-arrow-right" aria-hidden="true"></i>
                    {"  "}Más noticias
                  </b>
                </a>
              </>
            }
            color="error"
          ></NoticeBar>
        </div>
      )}
    </>
  );
}

export default Notices;
