import logo from "../../assets/background-travel.jpg";

function SectionImage(props) {
  return (
    <div
      style={{
        height: !!props.height ? props.height : "64px",
        maxHeight: "50vh",
      }}
      className="section-image"
    >
      <div className="title">
        <h1>{props.title}</h1>
      </div>
      <div className="children">
        {!!props.children && <>{props.children}</>}
      </div>
      {!!props.showImage && (
        <img
          className="img img-responsive"
          src={props.src ? props.src : logo}
          style={{ width: "100%", marginTop: "-75px" }}
          alt={props.alt ? props.alt : props.title}
        />
      )}
    </div>
  );
}

export default SectionImage;
