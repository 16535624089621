import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Input, Button, Form, notification } from "antd";
import { FaPaperPlane } from "react-icons/fa";
import { sendMessage } from "./messaging-services";

const { TextArea } = Input;

function SendMessageForm(props) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const response = await sendMessage({
        senderId: props.senderId,
        recipientId: props.recipientId,
        content: values.message,
      });
      if (response)
        notification.success({
          message: "Mensaje enviado",
        });
      form.resetFields();
      props.afterFinish();
    } catch (error) {
      if (error.response.status === 401) {
        const stateObj = { state: { from: window.location.pathname } };
        navigate("/salir", stateObj);
      } else {
        notification.warning({
          message: "Error al enviar el mensaje",
        });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form
      form={form}
      name={"message-form-" + props.senderId}
      onFinish={onFinish}
    >
      <Form.Item
        name={"message"}
        rules={[
          {
            required: true,
            message: "Por favor, escribe unn mensaje",
          },
        ]}
      >
        <TextArea
          autoSize={{ minRows: 1, maxRows: 3 }}
          maxLength={500}
          minLength={5}
          placeholder="Escribe tu mensaje"
        />
      </Form.Item>
      <div style={{ textAlign: "right" }}>
        <Button
          className="me-2 d-none"
          style={{ height: "auto" }}
          onClick={() => {
            props.afterCancel();
          }}
        >
          Cerrar
        </Button>
        <Button
          htmlType="submit"
          className="button"
          loading={loading}
        >
          <FaPaperPlane/>
        </Button>
      </div>
    </Form>
  );
}

export default SendMessageForm;
