import React, { useState, useEffect } from "react";
import axios from "axios";
import { Image, List, PullToRefresh } from "antd-mobile";
import { Skeleton } from "antd";
import { Helmet } from "react-helmet";
import { API_PUSH_URL, trailing_key, CLIENT_SITE_URL } from "../../constants";
import { getChunkByKey } from "../../services/utils-services";
import { renderAsDiv } from "../../pages/portal/chunk-page";
import PageFooter from "../../layout/footer";
import PageHeader from "../../layout/header";
import SectionImage from "../../components/visual/section-image";

function News() {
  const [notifications, setNotifications] = useState([]);
  const [text, setText] = useState("");

  useEffect(() => {
    const fetchNotifications = async () => {
      const uri = API_PUSH_URL + trailing_key;
      axios
        .get(uri, {
          timeout: 5000, // Establece el timeout
        })
        .then((response) => {
          setNotifications(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    };
    const fetchDataChunk = async (chunk) => {
      try {
        const res = await getChunkByKey(chunk);
        setText(res.content);
      } catch (error) {
        console.log("Error fetching chunk");
      }
    };
    fetchNotifications();
    fetchDataChunk("news");
  }, []);

  return (
    <>
      <Helmet>
        <link rel="canonical" href={CLIENT_SITE_URL + "/noticias"} />
        <meta name="description" content="Noticias Destino Docente" />
      </Helmet>
      <PageHeader />
      <SectionImage title="Noticias Destino Docente" />
      <div className="row">
        <div className="col-md-8 offset-md-2 col-xs-12 mt-4 text-page">
          {renderAsDiv(text)}
          {notifications && notifications.length > 0 ? (
            <PullToRefresh
              pullingText="Actualizar"
              refreshingText="Actualizando ..."
              completeText="Actualizado"
              canReleaseText="Soltar"
            >
              <List>
                {notifications.map((item) => (
                  <List.Item
                    key={item.id}
                    prefix={
                      <Image
                        src={item.icon}
                        style={{ borderRadius: 3 }}
                        fit="cover"
                        width={50}
                        height={50}
                      />
                    }
                    description={
                      <div
                        dangerouslySetInnerHTML={{ __html: item.body }}
                        style={{ maxWidth: "60vw" }}
                      />
                    }
                  >
                    {item.title}
                  </List.Item>
                ))}
              </List>
            </PullToRefresh>
          ) : (
            <div className="text-center my-4">
              <Skeleton className="p-3 mt-3" active />
            </div>
          )}
        </div>
      </div>
      <PageFooter />
    </>
  );
}

export default News;
