import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaChalkboardTeacher, FaExternalLinkAlt } from "react-icons/fa";
import { Badge } from "antd";

//import FavoriteButton from "./visual/fav-pos-center-button.js";
import { API_CENTER_URL, trailing_key } from "../constants/index.js";
//import { getLocalUserId } from "../services/auth-services.js";

/**
 * vacantes SIPRI o similares
 * @param {*} props
 * @returns
 */
function CenterTempPositions(props) {
  const [positions, setPositions] = useState([]);

  useEffect(() => {
    if (!!props.code) {
      getCenterPositionsByCode(props.code);
    }
  }, [props.code]);

  const getCenterPositionsByCode = async (code) => {
    const uri = API_CENTER_URL + `/plazas/latest/center/${code}` + trailing_key;
    await axios
      .get(uri, {
        timeout: 5000, // Establece el timeout
      })
      .then((response) => {
        setPositions(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <>
      {!!positions && positions?.length > 0 && (
        <>
          <div className="mb-2">
            <FaChalkboardTeacher />
            <a
              href="https://www.juntadeandalucia.es/educacion/sipri/inicio/"
              target="_blank"
              rel="noreferrer"
            >
              <b>{"    "}Últimas vacantes (SIPRI Andalucía):</b>
              <FaExternalLinkAlt className="small" />
            </a>
          </div>

          <ul style={{ listStyle: "none", paddingLeft: "0" }}>
            {positions?.map((pos) => {
              return (
                <li key={pos.cod_puesto} className="px-0 py-1">
                  <Badge count={1} color="#8C5659" /> {pos.puesto} {pos.tipo}
                  {/* 
                  <FavoriteButton
                    centerCode={props.code}
                    positionCode={pos.cod_puesto}
                    userId={getLocalUserId()}
                  />
                  */}
                </li>
              );
            })}
          </ul>
        </>
      )}
    </>
  );
}

export default CenterTempPositions;
