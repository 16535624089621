import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { List, Row, Col, Card, notification, Typography, Skeleton } from "antd";
import {
  FaArrowDown,
  FaCar,
  FaWalking,
  FaUniversity,
  FaSchool,
  FaCalendarAlt,
  FaRoad,
} from "react-icons/fa";
import moment from "moment/moment";
import slugify from "react-slugify";
import TravelButton from "../../components/travels/travel-button";
import TravelDeleteButton from "../../components/travels/travel-delete-button";
import SectionImage from "../../components/visual/section-image";
import PageHeader from "../../layout/header";
import ShareMenu from "../../components/visual/share-menu";
import { ShareFBButton, ShareWSButton } from "../../utils/utils";
import { API_TRAVELS_URL, trailing_key } from "../../constants";
import { getChunkByKey } from "../../services/utils-services";
import axiosAuth from "../../components/utils/auth-interceptor";
import { renderAsDiv } from "../portal/chunk-page";
import { IES_DENOM } from "../../constants";
import { getLocalUserId } from "../../services/auth-services";
import { denomAbrev } from "../../constants/data";

const { Meta } = Card;
const { Text } = Typography;
const iconSize = 20;

function UserTravelPage() {
  const [travels, setTravels] = useState([]);
  const [text, setText] = useState("");
  let navigate = useNavigate();

  function fetchUserTravels() {
    const uri = `${API_TRAVELS_URL}/auth/user-travels/${trailing_key}`;
    axiosAuth
      .get(uri)
      .then((response) => {
        if (response.data) {
          setTravels(response.data);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          const stateObj = { state: { from: window.location.pathname } };
          navigate("/salir", stateObj);
        } else {
          notification.warning({
            message: "Se ha producido un error",
          });
        }
      });
  }

  useEffect(() => {
    document.title = "Destino Docente. Viajes a tu destino docente";
    fetchUserTravels();

    const fetchDataChunk = async (chunk) => {
      try {
        const res = await getChunkByKey(chunk);
        setText(res.content);
      } catch (error) {
        console.log("Error fetching chunk");
      }
    };
    fetchDataChunk("UserTravelList");
  }, []);

  const afterTravelDelete = () => {
    fetchUserTravels();
  };

  const renderItem = (item) => {
    return (
      <List.Item key={item.id} className="pt-0">
        <Card
          style={{ width: "100%" }}
          title={
            <Text
              style={{
                whiteSpace: "normal" /* Permite saltos de línea */,
                wordBreak: "break-word" /* Rompe las palabras largas */,
              }}
            >
              <div className="mt-3">
                <h6>
                  {item.municipality_from.name} (
                  {item.municipality_from.province})
                </h6>
                <FaArrowDown
                  className="mt-1 mb-2"
                  style={{ color: "rgba(246, 9, 9, 0.8)" }}
                />
                <h6>
                  {denomAbrev[item.center_to.denom]} "{item.center_to.name}" de{" "}
                  {item.center_to.municipality} ({item.center_to.province})
                </h6>
              </div>
            </Text>
          }
          actions={[
            item.creator.id == getLocalUserId() && (
              <>
                <TravelDeleteButton
                  userId={getLocalUserId()}
                  travel={item}
                  afterFinish={() => {
                    afterTravelDelete();
                  }}
                />
                {/* <TravelButton travel={item} children={<>{"Editar"}</>} /> */}
              </>
            ),
          ]}
          extra={[
            <ShareMenu title="Comparte este viaje">
              <ul className="share-menu">
                <li key={item.id + "-1"} className="py-2">
                  <ShareWSButton
                    color="rgb(0,168,132)"
                    size="28"
                    message={
                      "¡Míralo en en Destino Docente! Viaje de " +
                      `${item?.municipality_from.name} a "${item?.center_to.name}" de ${item?.center_to.municipality}` +
                      ` https://destinodocente.es/compartir-coche/${item?.id}/${slugify(
                        item?.municipality_from.name +
                          " a " +
                          item?.center_to.name
                      )}`
                    }
                  >
                    <span className="ms-2">WhatsApp</span>
                  </ShareWSButton>
                </li>
                <li>
                  <ShareFBButton
                    color="#1877f2"
                    size="28"
                    url={`https://destinodocente.es/compartir-coche/${
                      item?.id
                    }/${slugify(
                      item?.municipality_from.name +
                        " a " +
                        item?.center_to.name
                    )}`}
                    message={`¡Míralo en Destino Docente! Viaje de ${item?.municipality_from.name} a ${item?.center_to.name} de ${item?.center_to.municipality}`}
                  >
                    <span className="ms-2">Facebook</span>
                  </ShareFBButton>
                </li>
              </ul>
            </ShareMenu>,
          ]}
        >
          <Row>
            {/*
            <Col md={24}>
              {isMobile ? (
                <div className="text-center">
                  <h6>
                    {item.municipality_from.name} (
                    {item.municipality_from.province})
                  </h6>
                  <FaArrowDown className="mt-1 mb-2" />
                  <h6>
                    {denomAbrev[item.center_to.denom]} "{item.center_to.name}"
                    de {item.center_to.municipality} ({item.center_to.province})
                  </h6>
                </div>
              ) : (
                <h6>
                  {item.municipality_from.name} (
                  {item.municipality_from.province}
                  )
                  <FaArrowRight className="mx-2" />
                  {denomAbrev[item.center_to.denom]} "{item.center_to.name}" de{" "}
                  {item.center_to.municipality} ({item.center_to.province})
                </h6>
              )}
            </Col>
            */}
            <Col>
              <Row gutter={[12, 12]} className="mt-3 col-xs-12">
                <Col>
                  {item.schedule === IES_DENOM ? (
                    <>
                      <FaUniversity
                        size={iconSize}
                        style={{ color: "darkred" }}
                      />{" "}
                      Instituto
                    </>
                  ) : (
                    <>
                      <FaSchool
                        size={iconSize}
                        style={{ color: "limegreen" }}
                      />{" "}
                      Colegio
                    </>
                  )}
                </Col>
                <Col>
                  {item.travel_type === "C" ? (
                    <>
                      <FaCar size={iconSize} style={{ color: "limegreen" }} />{" "}
                      Coche
                    </>
                  ) : (
                    <>
                      <FaWalking size={iconSize} style={{ color: "darkred" }} />{" "}
                      Sin Coche
                    </>
                  )}
                </Col>
                <Col>
                  <FaCalendarAlt size={iconSize - 2} />
                  <span className="ms-1">
                    {item?.start_date
                      ? moment(item?.start_date).format("D/M/YY")
                      : ""}{" "}
                    a{" "}
                    {item?.end_date
                      ? moment(item?.end_date).format("D/M/YY")
                      : ""}
                  </span>
                </Col>
                <Col>
                  {item?.roadDuration && (
                    <>
                      <FaRoad size={iconSize} /> {item.roadDuration}
                    </>
                  )}
                </Col>
                <Col></Col>
              </Row>
            </Col>
          </Row>
        </Card>
      </List.Item>
    );
  };

  return (
    <>
      <PageHeader />
      <SectionImage title={"Tus viajes"}>
        <TravelButton
          munCodeOrig={null}
          munNameOrig={null}
          centerDest={null}
          userId={getLocalUserId()}
          afterFinish={() => {
            fetchUserTravels();
          }}
        />
      </SectionImage>
      <div className="col-md-8 offset-md-2 col-xs-12 text-page">
        {renderAsDiv(text)}
        {!!travels ? (
          <List
            className="item-list"
            dataSource={travels}
            renderItem={renderItem}
            locale={{
              emptyText: (
                <Card
                  cover={<SectionImage showImage={true} height={"auto"} />}
                  className="my-4"
                >
                  <Meta
                    title="No tienes viajes publicados."
                    description={
                      <>
                        <TravelButton
                          munCodeOrig={null}
                          munNameOrig={null}
                          centerDest={null}
                          userId={getLocalUserId()}
                          afterFinish={() => {
                            fetchUserTravels();
                          }}
                          className="p-0"
                          text="Publica tu primer viaje"
                        />
                        <Link to="/compartir-coche">
                          <span> o busca viajes para compartir tu coche</span>
                        </Link>
                      </>
                    }
                  />
                </Card>
              ),
            }}
          />
        ) : (
          <>
            <Skeleton active />
          </>
        )}
      </div>
    </>
  );
}

export default UserTravelPage;
