import React from "react";
import { useEffect, useState } from "react";
import { Form, Input, Button, notification } from "antd";
import axios from "axios";
import { Helmet } from "react-helmet";

import PageHeader from "../../layout/header";
import PageFooter from "../../layout/footer";
import { API_PORTAL_URL, trailing_key, CLIENT_SITE_URL } from "../../constants";
import { renderAsP } from "./chunk-page";
import { getChunkByKey } from "../../services/utils-services";
import {
  getLocalUserEmail,
  getLocalUserFirstName,
  isAuth,
} from "../../services/auth-services";

const { TextArea } = Input;

function Contact() {
  const [form] = Form.useForm();
  const [text, setText] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    document.title = "Destino Docente. Contacta con nosotros";
    const fetchDataChunk = async (chunk) => {
      try {
        const res = await getChunkByKey(chunk);
        setText(res.content);
      } catch (error) {
        console.log("Error fetching chunk");
      }
    };
    fetchDataChunk("contact");
  }, []);

  const onFinish = async (values) => {
    try {
      setLoading(true);
      const uri = API_PORTAL_URL + `/contacto` + trailing_key;
      await axios.post(uri, values);
      notification.success({
        message: "Hemos recibido tu mensaje",
      });
      form.resetFields();
    } catch (error) {
      notification.warning({
        message: "Error al enviar el mensaje",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <link rel="canonical" href={CLIENT_SITE_URL + "/contacto"} />
        <meta name="description" content="Contacto a Destino Docente" />
      </Helmet>
      <PageHeader />
      <div className="jumbotron-wrapper">
        <div className="container">
          <div className="jumbotron">
            <div className="jumbotron-inner rounded col-md-8 col-xs-11 offset-md-2">
              <div className="p-3 mx-3">
                <h1>Contacto</h1>
                {renderAsP(text)}
                <Form
                  form={form}
                  name="contact_form"
                  onFinish={onFinish}
                  initialValues={{
                    ["name"]: isAuth() ? getLocalUserFirstName() : "",
                    ["email"]: isAuth() ? getLocalUserEmail() : "",
                  }}
                >
                  <Form.Item
                    name="name"
                    label="Nombre"
                    rules={[
                      {
                        required: true,
                        message: "Por favor, escribe tu nombre",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="email"
                    label="Email"
                    rules={[
                      {
                        required: true,
                        message: "Por favor, escribe tu correo electrónico",
                        type: "email",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="message"
                    label="Mensaje"
                    rules={[
                      {
                        required: true,
                        message: "Por favor, escribe un mensaje",
                      },
                    ]}
                  >
                    <TextArea rows={3} maxLength={500} />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="btn btn-success pull-right"
                      style={{ height: "auto" }}
                      loading={loading}
                    >
                      Enviar
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PageFooter />
    </>
  );
}

export default Contact;
