import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Skeleton } from "antd-mobile";
import { Helmet } from "react-helmet";
import { getChunkByKey } from "../../services/utils-services";
import PageFooter from "../../layout/footer";
import PageHeader from "../../layout/header";
import SectionImage from "../../components/visual/section-image";
import { CLIENT_SITE_URL } from "../../constants";

/**
 *
 * @param {*} htmlPart
 * @returns
 */
export const renderAsP = (htmlPart) => {
  return <p dangerouslySetInnerHTML={{ __html: htmlPart }} />;
};

/**
 *
 * @param {*} htmlPart
 * @returns
 */
export const renderAsDiv = (htmlPart) => {
  return htmlPart && <div dangerouslySetInnerHTML={{ __html: htmlPart }} />;
};

function ChunkPage(props) {
  const [chunk] = useState(props.chunkKey);
  const [text, setText] = useState("");

  let navigate = useNavigate();

  const back = () => {
    navigate(-1);
  };

  useEffect(() => {
    document.title = "Destino Docente. " + props.title;
  }, [props.title]);

  useEffect(() => {
    const fetchDataChunk = async (chunk) => {
      try {
        const res = await getChunkByKey(chunk);
        setText(res.content);
      } catch (error) {
        console.log("Error fetching chunk");
      }
    };

    fetchDataChunk(chunk);
  }, [chunk]);

  return (
    <>
      <Helmet>
        <link
          rel="canonical"
          href={CLIENT_SITE_URL + (props.path ? props.path : "")}
        />
        <meta name="description" content={props.title} />
      </Helmet>
      <PageHeader />
      <SectionImage title={props.title} />
      <div className="row">
        <div
          className="col-md-8 offset-md-2 col-xs-12 mt-4 text-page"
          style={{ minHeight: "75vh" }}
        >
          {text ? renderAsP(text) : <Skeleton active />}
        </div>
      </div>
      <PageFooter />
    </>
  );
}

export default ChunkPage;
