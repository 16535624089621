// solo deben utilizarse para dropdown, no para construir url
export const optionsDenom = [
  {
    label: "Colegio de Educación Infantil y Primaria",
    value: "7",
  },
  {
    label: "Colegio de Educación Primaria",
    value: "12",
  },
  {
    label: "Colegio Público Rural",
    value: "13",
  },
  {
    label: "Instituto de Educación Secundaria",
    value: "14",
  },
  {
    label: "Centro Público Integrado FP",
    value: "22",
  },
];

/*
export const optionsDenomExt_ = [
  {
    label: "Colegio de Infantil y Primaria",
    value: "7",
  },
  {
    label: "Colegio de Educación Infantil y Primaria",
    value: "7",
  },
  {
    label: "Colegio de Primaria",
    value: "12",
  },
  {
    label: "Colegio de Educación Primaria",
    value: "12",
  },
  {
    label: "Colegio Público Rural",
    value: "13",
  },
  {
    label: "Instituto de Secundaria",
    value: "14",
  },
  {
    label: "Instituto de Educación Secundaria",
    value: "14",
  },
  {
    label: "Centro Público Integrado FP",
    value: "22",
  },
];
*/

export const denomAbrev = {
  "Instituto de Educación Secundaria": "I.E.S",
  "Colegio de Educación Infantil y Primaria": "C.E.I.P.",
  "Colegio de Educación Primaria": "C.E.P.",
  "Colegio Público Rural": "C.P.R.",
  "Centro público integrado de formación profesional": "C.P.I.F.P.",
  "Escuela Oficial de Idiomas": "E.O.I.",
};

// slider distancias
export const distanceMarks = {
  10: "10Km",
  25: "25Km",
  50: "50Km",
  75: "75Km",
  100: "100Km",
};

export const priceMarks = {
  100: "100€",
  200: "200€",
  300: "300€",
  400: ">400€",
};

export const optionsSort = [
  {
    label: "Menor tiempo",
    value: "asc",
  },
  {
    label: "Mayor Tiempo",
    value: "desc",
  },
];

export const optionRegions = [
  {
    label: "Andalucía",
    value: "1",
  },
  {
    label: "Aragón",
    value: "5",
  },
  {
    label: "Castilla-La Mancha",
    value: "9",
  },
  {
    label: "Castilla y León",
    value: "4",
  },
  {
    label: "Comunidad Valenciana",
    value: "7",
  },
  {
    label: "Comunidad de Madrid",
    value: "3",
  },
  {
    label: "Galicia",
    value: "8",
  },
  {
    label: "Región de Murcia",
    value: "2",
  },
];

export const optionRegionsAll = [
  {
    label: "Todos",
    value: null,
  },
  {
    label: "Andalucía",
    value: "1",
  },
  {
    label: "Aragón",
    value: "5",
  },
  {
    label: "Castilla-La Mancha",
    value: "9",
  },
  {
    label: "Castilla y León",
    value: "4",
  },
  {
    label: "Comunidad Valenciana",
    value: "7",
  },
  {
    label: "Comunidad de Madrid",
    value: "3",
  },
  {
    label: "Galicia",
    value: "8",
  },
  {
    label: "Región de Murcia",
    value: "2",
  },
];

export const provincesByAutonomousCommunity = {
  1: [
    { label: "Almería", value: "04" },
    { label: "Cádiz", value: "11" },
    { label: "Córdoba", value: "14" },
    { label: "Granada", value: "18" },
    { label: "Huelva", value: "21" },
    { label: "Jaén", value: "23" },
    { label: "Málaga", value: "29" },
    { label: "Sevilla", value: "41" },
  ],
  5: [
    { label: "Huesca", value: "22" },
    { label: "Teruel", value: "44" },
    { label: "Zaragoza", value: "50" },
  ],
  4: [
    { label: "Ávila", value: "05" },
    { label: "Burgos", value: "09" },
    { label: "León", value: "24" },
    { label: "Palencia", value: "34" },
    { label: "Salamanca", value: "37" },
    { label: "Segovia", value: "40" },
    { label: "Soria", value: "42" },
    { label: "Valladolid", value: "47" },
    { label: "Zamora", value: "49" },
  ],
  7: [
    { label: "Alicante", value: "03" },
    { label: "Castellón", value: "12" },
    { label: "Valencia", value: "46" },
  ],
  3: [{ label: "Madrid", value: "28" }],
  8: [
    { label: "A Coruña", value: "15" },
    { label: "Lugo", value: "27" },
    { label: "Ourense", value: "32" },
    { label: "Pontevedra", value: "36" },
  ],
  2: [{ label: "Murcia", value: "30" }],
  9: [
    { label: "Albacete", value: "02" },
    { label: "Ciudad Real", value: "13" },
    { label: "Cuenca", value: "16" },
    { label: "Guadalajara", value: "19" },
    { label: "Toledo", value: "45" },
  ],
};

export const optionsSchedule = [
  {
    label: "Horario de Colegio",
    value: "7",
  },
  {
    label: "Horario de Instituto",
    value: "14",
  },
];
