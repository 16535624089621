import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Form, Input, Button, notification } from "antd";
import axios from "axios";
import { API_AUTH_URL, trailing_key } from "../constants";

//https://chat.openai.com/share/d5adebab-7835-44d4-96e6-12915477c6d0

const RegistrationForm = () => {
  let navigate = useNavigate();

  const [form] = Form.useForm();
  const [formErrors, setFormErrors] = useState({});

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  //const [password2, setPassword2] = useState("");
  //const [tos, setTos] = useState(false);

  const onFinish = async () => {
    try {
      await axios.post(
        API_AUTH_URL + "/registration" + trailing_key,
        {
          first_name: name,
          last_name: name,
          email: email,
          password: password,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      notification.success({
        message: "Se ha creado la cuenta correctamente",
        description:
          'Ahhora puedes acceder a tu cuenta. También puedes modificar tus datos en el menú "Tu cuenta"',
      });

      navigate("/");
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setFormErrors(error.response.data);
      } else {
        notification.error({
          message: "Error al registrar el usuario",
        });
        console.error("Error al registrar:", error.message);
      }
    }
  };

  return (
    <Form form={form} onFinish={onFinish}>
      <Form.Item
        name="email"
        rules={[
          { required: true, message: "Por favor, introduce tu email" },
          { type: "email", message: "El email no es válido" },
        ]}
        validateStatus={formErrors.email && "error"}
        help={formErrors.email && formErrors.email.join(" ")}
      >
        <Input
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Correo electrónico"
        />
      </Form.Item>
      <Form.Item
        name="first_name"
        rules={[{ required: true, message: "Por favor, introduce tu nombre" }]}
      >
        <Input
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Nombre"
          validateStatus={formErrors.first_name && "error"}
          help={formErrors.first_name && formErrors.first_name.join(" ")}
        />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[
          { required: true, message: "Por favor, introduce tu contraseña" },
          { min: 4, message: "La contraseña debe tener al menos 4 caracteres" },
        ]}
      >
        <Input.Password
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Contraseña"
          validateStatus={formErrors.password && "error"}
          help={formErrors.password && formErrors.password.join(" ")}
        />
      </Form.Item>
      {/* 
      <Form.Item label="Repite contraseña" name="password2" required>
        <Input.Password
          value={password2}
          onChange={(e) => setPassword2(e.target.value)}
        />
      </Form.Item>
      <Form.Item name="tos" required>
        <Checkbox checked={tos} onChange={(e) => setTos(e.target.value)}>
          Acepto los <a href="/terminos-de-uso">términos de uso</a> y{" "}
          <a href="/privacidad">política de privacidad.</a>
        </Checkbox>
      </Form.Item>
      */}
      <Form.Item>
        <Button type="primary" htmlType="submit" className="pull-right">
          Registrarse
        </Button>
      </Form.Item>
    </Form>
  );
};

export default RegistrationForm;
