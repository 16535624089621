import React, { useState, useEffect } from "react";
import { List, Row, Col, Card, Typography, Skeleton, Image } from "antd";
import {
  FaHouseUser,
  FaUsers,
  FaBed,
  FaBath,
  FaBuilding,
} from "react-icons/fa";
import moment from "moment/moment";
import axios from "axios";
import { isMobile } from "react-device-detect";
import slugify from "react-slugify";
import { Helmet } from "react-helmet";
import { getChunkByKey } from "../../services/utils-services";
import { getLocalUserId } from "../../services/auth-services";
import {
  API_ADS_URL,
  api_key,
  CLIENT_SITE_URL,
  MEDIA_URL,
} from "../../constants";
import { renderAsDiv } from "../portal/chunk-page";
import { buildQueryString, getAdTitle } from "../../utils/utils";
import PageFooter from "../../layout/footer";
import PageHeader from "../../layout/header";
import MessageButton from "../../components/messaging/message-button";
import SectionImage from "../../components/visual/section-image";
import { ShareWSButton, ShareFBButton } from "../../utils/utils";
import AdSearchForm from "../../components/ads/ad-search-form";
import AdButton from "../../components/ads/ad-button";
import { denomAbrev } from "../../constants/data";
import ShareMenu from "../../components/visual/share-menu";
import background from "../../assets/background-ad.jpg";
import { isAuth } from "../../services/auth-services";
import FavButton from "../../components/visual/fav";

const { Meta } = Card;
const { Text } = Typography;

export const renderItem = (item) => {
  return (
    <List.Item key={item.id} className="pt-0">
      <Card
        style={{ width: "100%" }}
        title={
          <Text
            style={{
              whiteSpace: "normal",
              wordBreak: "break-word",
            }}
          >
            <h5>
              {item.ad_type === "H" ? (
                <FaHouseUser
                  size="18"
                  style={{ marginTop: "-2px", color: "LimeGreen" }}
                />
              ) : (
                <FaUsers
                  size="16"
                  style={{ marginTop: "-2px", color: "darkred" }}
                />
              )}
              <span className="ms-2">{getAdTitle(item)}</span>
            </h5>
          </Text>
        }
        actions={
          item.creator.id != getLocalUserId() && [
            <MessageButton
              senderId={getLocalUserId()}
              recipientId={item.creator.id}
            >
              <span className="ms-2">Contactar</span>
            </MessageButton>,
            isAuth() && <FavButton contentTypeId={44} objectId={item.id} />,
          ]
        }
        extra={[
          <ShareMenu title="Comparte este anuncio">
            <ul className="share-menu">
              <li key={item.id + "-1"} className="py-2">
                <ShareWSButton
                  color="rgb(0,168,132)"
                  size="28"
                  message={
                    "¡Míralo en en Destino Docente! " +
                    `${getAdTitle(item)}` +
                    ` https://destinodocente.es/compartir-casa/${
                      item?.id
                    }/${slugify(getAdTitle(item))}`
                  }
                >
                  <span className="ms-2">WhatsApp</span>
                </ShareWSButton>
              </li>
              <li key={item.id + "-2"} className="py-2">
                <ShareFBButton
                  color="#1877f2"
                  size="26"
                  url={`https://destinodocente.es/compartir-casa/${
                    item?.id
                  }/${slugify(getAdTitle(item))}`}
                  message={`¡Míralo en Destino Docente! ${getAdTitle(item)} `}
                >
                  <span className="ms-2">Facebook</span>
                </ShareFBButton>
              </li>
            </ul>
          </ShareMenu>,
        ]}
        cover={
          <>
            {item.images && item.images.length > 0 && item.images[0].image ? (
              <Image
                src={`${MEDIA_URL}${item.images[0].image}`}
                alt="Foto del anuncio"
                style={{
                  maxHeight: "250px",
                  width: "100%",
                  objectFit: "cover",
                }}
              />
            ) : (
              <div
                className="py-4 text-center"
                style={{ backgroundColor: "#efefef" }}
              >
                <FaBuilding
                  size="155px"
                  style={{
                    color: "#aaaaaa",
                  }}
                />
              </div>
            )}
          </>
        }
      >
        <Row gutter={[8, 8]} className="mt-2">
          {!!item.price && item.price > 0 && (
            <Col md={24} xs={24}>
              <h5>{Math.round(item.price).toString()} €</h5>
            </Col>
          )}
          {!!item.description && (
            <Col md={24} xs={24}>
              <p>{item.description}</p>
            </Col>
          )}
          {!!item.n_rooms && (
            <Col md={4} xs={4}>
              <FaBed size={"20"} /> {item.n_rooms}
            </Col>
          )}
          {!!item.n_baths && (
            <Col md={4} xs={4}>
              <FaBath size={"18"} /> {item.n_baths}
            </Col>
          )}
          {!!item.centers && item.centers.length > 0 && (
            <Col md={16} xs={24}>
              <i className="fa fa-map-marker " aria-hidden="true" />
              {" Cerca de " +
                denomAbrev[item.centers[0].denom] +
                " " +
                item.centers[0].name}
            </Col>
          )}
        </Row>
        {item.creator.id == getLocalUserId() && (
          <Row gutter={[8, 8]}>
            <Col md={24} xs={24}>
              <div className="pull-right">
                <i>
                  {item?.created ? moment(item?.created).format("D/M/YY") : ""}
                </i>
              </div>
            </Col>
          </Row>
        )}
      </Card>
    </List.Item>
  );
};

export function AdSearchPage() {
  const [ads, setAds] = useState([]);
  const [text, setText] = useState("");

  const fetchDataAd = async () => {
    const params = [{ key: "api_key", value: api_key }];

    const queryString = buildQueryString(params);
    const uri = `${API_ADS_URL}/search-ads/?${queryString}`;

    try {
      const response = await axios.get(uri);
      if (response.data) {
        setAds(response.data);
      }
    } catch (error) {
      console.error("Hubo un error recuperar los datos", error);
    }
  };

  useEffect(() => {
    document.title = "Destino Docente. Busca alojamiento en tu destino docente";
  }, []);

  useEffect(() => {
    fetchDataAd();

    const fetchDataChunk = async (chunk) => {
      try {
        const res = await getChunkByKey(chunk);
        setText(res.content);
      } catch (error) {
        console.log("Error fetching chunk");
      }
    };

    fetchDataChunk("AdSearch");
  }, []);

  return (
    <>
      <Helmet>
        <link rel="canonical" href={CLIENT_SITE_URL + "/compartir-casa"} />
        <meta
          name="description"
          content="Comparte casa y ahorra en el alojamiento en tu centro de destino."
        />
      </Helmet>
      <PageHeader />
      <SectionImage title={"¿Buscas casa?"}>
        <AdButton
          munCode={null}
          munName={null}
          userId={getLocalUserId()}
          afterFinish={() => {
            fetchDataAd();
          }}
        />
      </SectionImage>
      <div className="col-md-8 offset-md-2 col-xs-12 text-page">
        <div className={isMobile ? "mt-0" : "row my-4"}>
          <div className="col-md-5 col-xs-12">
            <Card
              style={{ border: "2px solid #7ebf8e" }}
              className={isMobile ? "my-4 mx-2 pt-4" : "pt-4"}
            >
              <AdSearchForm setAds={setAds} />
            </Card>
            {renderAsDiv(text)}
          </div>
          <div className="col-md-7 col-xs-12">
            <div className={isMobile ? "mx-2 mb-4" : ""}>
              {!!ads ? (
                <List
                  className="item-list"
                  dataSource={ads}
                  renderItem={renderItem}
                  locale={{
                    emptyText: (
                      <Card
                        cover={
                          <img
                            alt="Destino Docente Anuncios"
                            src={background}
                          />
                        }
                      >
                        <Meta
                          title={
                            <div className="my-2">
                              ¡Vaya! No hay anuncios publicados.
                            </div>
                          }
                          description={
                            <div className="text-center mt-3">
                              <AdButton
                                munCode={null}
                                munName={null}
                                userId={getLocalUserId()}
                                afterFinish={() => {
                                  fetchDataAd();
                                }}
                                text="Publica un anuncio aquí"
                              />
                            </div>
                          }
                        />
                      </Card>
                    ),
                  }}
                />
              ) : (
                <>
                  <Skeleton active />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <PageFooter />
    </>
  );
}
