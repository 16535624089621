import React, { useState, useEffect } from "react";
import {
  useNavigate,
  useParams,
  useLocation,
  useSearchParams,
} from "react-router-dom";

import axiosAuth from "../../components/utils/auth-interceptor";
import { API_AUTH_URL, trailing_key } from "../../constants";
import { clearStorageByKey } from "../../services/storage-service";
import {
  AUTH_TOKEN,
  REFRESH_TOKEN,
  USER_ID,
  USER_EMAIL,
  USER_FIRST_NAME,
  REDIRECT_URL,
} from "../../services/auth-services";
import { delay } from "../../utils/utils";
import logo from "../../assets/logo.png";

/**
 * para continuar la navegación puede recibir un location state (from) o un parametro get por url (next)
 * @returns
 */
export const Logout = () => {
  let navigate = useNavigate();
  const location = useLocation();
  // parámetros para continuar la navegación
  const { msg: pMsg, from: pFrom } = useParams(); // param url NO GET
  const [fromParams] = useSearchParams(); // GET
  const [from, setFrom] = useState("/");
  // mensaje al usuario al salir
  const [message, setMessage] = useState("");

  // si recibo por url un mensaje
  useEffect(() => {
    setMessage(pMsg);
  }, [pMsg]);

  // si recibo por url GET
  useEffect(() => {
    setFrom(fromParams.get("from"));
  }, [fromParams]);

  // si recibo por state
  useEffect(() => {
    console.log("location.state",location.state)
    if (location.state) {
      setFrom(location.state.from ? location.state.from : REDIRECT_URL);
      setMessage(location.state.message ? location.state.message : "");
    }
  }, [location.state]);

  // param url
  useEffect(() => {
    setFrom(pFrom);
  }, [pFrom]);

  useEffect(() => {
    (async () => {
      try {
        await axiosAuth.post(API_AUTH_URL + "/logout" + trailing_key, {
          refresh_token: localStorage.getItem(REFRESH_TOKEN),
        });
      } catch (error) {
        console.error("logout not working");
      } finally {
        // borro toda la info se sesión
        clearStorageByKey(REFRESH_TOKEN);
        clearStorageByKey(AUTH_TOKEN);
        clearStorageByKey(USER_EMAIL);
        clearStorageByKey(USER_FIRST_NAME);
        clearStorageByKey(USER_ID);
        console.log("logout",from);
        await delay(4000);
        navigate(from);
      }
    })();
  }, [navigate]);

  return (
    <div className="col-xs-12 jumbotron-wrapper" style={{ height: "100vh" }}>
      <div className="container">
        <div className="jumbotron">
          <div className="jumbotron-inner rounded">
            <div className="mb-2">
              <br />
              <a href="/">
                <img
                  src={logo}
                  className="img img-responsive main-logo"
                  alt="Destino Docente logo"
                />
              </a>
              <br />
              <div className="row mt-1">
                <div className="col-xs-12">
                  <h4>Se ha cerrado la sesión</h4>
                  <br />
                  {message}
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
