export const handleStyle = {
    height: "60px", // aumentar la altura
    width: "60px", // aumentar el ancho
    marginTop: "0", // centrar el botón verticalmente
    marginLeft: "20px",
  };
  
  export const trackStyle = {
    backgroundColor: "#26400C",
  };
  
  export const railStyle = {
    backgroundColor: "#efefef",
  };
  