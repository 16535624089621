import React, { useEffect, useState } from "react";
import axios from "axios";
import { SITE_URL } from "../constants";
import background from "../assets/logo.png";

const EmbeddedContent = (props) => {
  const [content, setContent] = useState("");

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const response = await axios.get(SITE_URL + "/portal/proxy/", {
          headers: {
            "Content-Type": "text/html",
          },
        });
        setContent(response.data);
      } catch (error) {
        console.error("Error fetching content:", error);
      }
    };

    fetchContent();
  }, []);

  return (
    <>
      {!!content ? (
        <div dangerouslySetInnerHTML={{ __html: content }} />
      ) : (
        <>
          {!!props.background && (
            <img
              alt="Destino Docente publicidad"
              src={background}
              style={{ maxWidth: "99vw" }}
              className="img img-responsive main-logo"
            />
          )}
        </>
      )}
    </>
  );
};

export default EmbeddedContent;
