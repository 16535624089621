import React, { useEffect, useRef } from "react";
import mapaCom from "../../assets/mapa_com_id.svg";
import { isMobile } from "react-device-detect";

const MapaComunidades = () => {
  const objectRef = useRef(null);

  useEffect(() => {
    const object = objectRef.current;

    const loadHandler = () => {
      const svgDocument = object.contentDocument;
      if (svgDocument) {
        const regions = [
          "Andalucia",
          "Aragon",
          "Castilla-La-Mancha",
          "Castilla-y-Leon",
          "Comunidad-de-Madrid",
          "Comunidad-Valenciana",
          "Galicia",
          "Region-de-Murcia",
        ];

        regions.forEach((regionId) => {
          const region = svgDocument.getElementById(regionId);
          if (region) {
            region.style.cursor = "pointer";
            region.onclick = () => {
              const urlMap = {
                Andalucia: "/mapas/1/Andalucía",
                Aragon: "/mapas/5/Aragón",
                "Castilla-La-Mancha": "/mapas/9/Castilla-La Mancha",
                "Castilla-y-Leon": "/mapas/4/Castilla y León",
                "Comunidad-de-Madrid": "/mapas/3/Comunidad de Madrid",
                "Comunidad-Valenciana": "/mapas/7/Comunidad Valenciana",
                Galicia: "/mapas/8/Galicia",
                "Region-de-Murcia": "/mapas/2/Región de Murcia",
              };
              window.location.href = urlMap[regionId];
            };
            region.addEventListener("mouseover", () => {
              region.style.fill = "#26400C"; // Cambia el color de relleno al hacer hover
            });
            region.addEventListener("mouseout", () => {
              region.style.fill = "#7ebf8e"; // Restaura el color original al quitar el mouse
            });
          }
        });
      }
    };

    object.addEventListener("load", loadHandler);

    return () => {
      object.removeEventListener("load", loadHandler);
    };
  }, []);

  return (
    <div style={{ width: isMobile ? "90vw" : "50vw" }}>
      <object
        ref={objectRef}
        type="image/svg+xml"
        data={mapaCom}
        style={{ width: "99%" }}
      >
        <img
          src={mapaCom}
          className="img img-responsive"
          alt="Destinos docentes de Andalucía, Aragón, Catilla-La Mancha, Castilla y León
              Comunidad de Madrid, Comunidad Valenciana y Región de Murcia"
          style={{ width: "99%"  }}
        />
      </object>
    </div>
  );
};

export default MapaComunidades;
