import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Card, List, Skeleton, Col, Row, Typography, Image } from "antd";
import moment from "moment/moment";
import { FaBlog } from "react-icons/fa";

import PageHeader from "../../layout/header";
import PageFooter from "../../layout/footer";
import SectionImage from "../../components/visual/section-image";
import ShareMenu from "../../components/visual/share-menu";
import { ShareFBButton, ShareWSButton } from "../../utils/utils";
import { API_BLOG_URL, trailing_key } from "../../constants";
//import { MEDIA_URL } from "../../constants";

import background from "../../assets/background-ad.jpg";

const { Meta } = Card;
const { Text } = Typography;

const BlogList = () => {
  const [blogs, setBlogs] = useState([]);

  // Función para obtener las entradas del blog
  const fetchBlogs = async () => {
    try {
      const uri = API_BLOG_URL + "/blogpost/" + trailing_key;
      const response = await axios.get(uri); // Suponiendo que la API REST está en esta ruta
      setBlogs(response.data);
    } catch (error) {
      console.error("Error fetching blog posts:", error);
    }
  };

  const renderItem = (item) => {
    return (
      <List.Item key={item.id} className="pt-0">
        <Card
          style={{
            minHeight: "400px",
            backgroundColor: item.status == "D" ? "#efefef" : "",
          }}
          title={
            <Text
              style={{
                whiteSpace: "normal",
                wordBreak: "break-word",
              }}
            >
              <h4>
                <Link to={`/blog/${item.id}/${item.slug}`}>{item.title}</Link>
              </h4>
            </Text>
          }
          cover={
            item && item.image ? (
              <Image
                //src={`${MEDIA_URL}${item.image}`}
                src={item.image}
                onError={(e) => {
                  e.target.src = { background };
                }}
                alt={item.image_title}
                style={{
                  objectFit: "cover",
                  height: "210px"
                }}
                preview={false}
              />
            ) : (
              <div
                className="py-4 text-center"
                style={{ backgroundColor: "#efefef" }}
              >
                <FaBlog
                  size="155px"
                  style={{
                    color: "#aaaaaa",
                  }}
                />
              </div>
            )
          }
          extra={[
            <ShareMenu title="Comparte esta entrada">
              <ul className="share-menu">
                <li key={item.id + "-1"} className="py-2">
                  <ShareWSButton
                    color="rgb(0,168,132)"
                    size="28"
                    message={
                      "¡Míralo en en Destino Docente! " +
                      `${item.title}` +
                      ` https://destinodocente.es/compartir-casa/${item?.id}/${item.slug}`
                    }
                  >
                    <span className="ms-2">WhatsApp</span>
                  </ShareWSButton>
                </li>
                <li key={item.id + "-2"} className="py-2">
                  <ShareFBButton
                    color="#1877f2"
                    size="26"
                    url={`https://destinodocente.es/compartir-casa/${item?.id}/${item.slug}`}
                    message={`¡Míralo en Destino Docente! ${item.title} `}
                  >
                    <span className="ms-2">Facebook</span>
                  </ShareFBButton>
                </li>
              </ul>
            </ShareMenu>,
          ]}
        >
          <Row gutter={[8, 8]} className="mt-2">
            {!!item.text && (
              <Col md={24} xs={24}>
                <p>{item.text}</p>
              </Col>
            )}
          </Row>
          <Row gutter={[8, 8]}>
            <Col md={24} xs={24}>
              <div className="pull-right">
                <i>
                  {item?.created ? moment(item?.created).format("D/M/YY") : ""}
                </i>
              </div>
            </Col>
          </Row>
        </Card>
      </List.Item>
    );
  };

  useEffect(() => {
    fetchBlogs();
  }, []);

  return (
    <>
      <PageHeader />
      <SectionImage title={"Blog Destino Docente"}></SectionImage>
      <div className="col-md-8 offset-md-2 col-xs-12 text-page">
        {!!blogs ? (
          <List
          className="mt-4"
            dataSource={blogs}
            renderItem={renderItem}
            locale={{
              emptyText: (
                <>
                  <Card
                    cover={
                      <img alt="Destino Docente Anuncios" src={background} />
                    }
                  >
                    <Meta
                      title={
                        <div className="mt-4">
                          ¡Vaya! No tienes anuncios publicados.
                        </div>
                      }
                      description={
                        <>
                          <Link to="/compartir-casa">
                            {" "}
                            <span className="undelined">
                              o busca anuncios para compartir casa.
                            </span>
                          </Link>
                        </>
                      }
                    />
                  </Card>
                </>
              ),
            }}
          />
        ) : (
          <>
            <Skeleton active />
          </>
        )}
      </div>
      <PageFooter />
    </>
  );
};

export default BlogList;
