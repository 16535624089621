import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Image } from "antd";

import { trailing_key, API_BLOG_URL } from "../../constants";
import PageFooter from "../../layout/footer";
import PageHeader from "../../layout/header";
import SectionImage from "../../components/visual/section-image";

const BlogPost = () => {
  const { id } = useParams(); // Obtener el ID de la entrada de blog desde la URL
  const [blogPost, setBlogPost] = useState(null);

  // Función para obtener una entrada de blog individual
  const fetchBlogPost = async () => {
    try {
      const uri = `${API_BLOG_URL}/blogpost/${id}/${trailing_key}`;
      const response = await axios.get(uri);
      setBlogPost(response.data);
    } catch (error) {
      console.error("Error fetching blog post:", error);
    }
  };

  // Ejecutar fetchBlogPost al cargar el componente
  useEffect(() => {
    fetchBlogPost();
  }, [id]);

  if (!blogPost) return <div>Loading...</div>;

  return (
    <>
      <PageHeader />
      <SectionImage title={blogPost.title}></SectionImage>
      <div className="col-md-8 offset-md-2 col-xs-12 text-page">
        <Image
          src={blogPost.image}
          preview={false}
          title={blogPost.image_title}
          className="mt-4"
        />
        <p>{blogPost.image_title}</p>

        <p>{blogPost.text}</p>
      </div>
      <PageFooter />
    </>
  );
};

export default BlogPost;
