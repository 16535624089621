import React, { useState, useEffect } from "react";
import { List, Row, Col, Card, Typography, Skeleton } from "antd";
import {
  FaCar,
  FaSchool,
  FaUniversity,
  FaWalking,
  FaCalendarAlt,
  FaRoad,
  FaArrowDown,
} from "react-icons/fa";
import { Helmet } from "react-helmet";
import axios from "axios";
import { isMobile } from "react-device-detect";
import moment from "moment/moment";
import slugify from "react-slugify";

import PageFooter from "../../layout/footer";
import PageHeader from "../../layout/header";
import TravelSearchForm from "../../components/travels/travel-sarch-form";
import TravelButton from "../../components/travels/travel-button";
import MessageButton from "../../components/messaging/message-button";
import SectionImage from "../../components/visual/section-image";
import {
  ShareWSButton,
  ShareFBButton,
  buildQueryString,
} from "../../utils/utils";
import ShareMenu from "../../components/visual/share-menu";
import { getChunkByKey } from "../../services/utils-services";
import { getLocalUserId } from "../../services/auth-services";
import {
  IES_DENOM,
  API_TRAVELS_URL,
  api_key,
  CLIENT_SITE_URL,
} from "../../constants";
import { denomAbrev } from "../../constants/data";
import { renderAsDiv } from "../portal/chunk-page";

import background from "../../assets/background-travel.jpg";

const { Meta } = Card;
const { Text } = Typography;

const iconSize = 18;

const renderItem = (item) => {
  return (
    <List.Item key={item.id} className="pt-0">
      <Card
        style={{ width: "100%" }}
        title={
          <Text
            style={{
              whiteSpace: "normal",
              wordBreak: "break-word",
            }}
          >
            <div className="mt-3">
              <h6>
                {item.municipality_from.name} ({item.municipality_from.province}
                )
              </h6>
              <FaArrowDown
                className="mt-1 mb-2"
                style={{ color: "rgba(246, 9, 9, 0.8)" }}
              />
              <h6>
                {denomAbrev[item.center_to.denom]} "{item.center_to.name}" de{" "}
                {item.center_to.municipality} ({item.center_to.province})
              </h6>
            </div>
          </Text>
        }
        actions={[
          item.creator.id != getLocalUserId() ? (
            <MessageButton
              senderId={getLocalUserId()}
              recipientId={item.creator.id}
            >
              <span className="ms-2">Contactar</span>
            </MessageButton>
          ) : (
            <></>
          ),
        ]}
        extra={[
          <ShareMenu title="Comparte este viaje">
            <ul className="share-menu">
              <li key={item.id + "-1"} className="py-2">
                <ShareWSButton
                  color="rgb(0,168,132)"
                  size="28"
                  message={
                    "¡Míralo en en Destino Docente! Viaje de " +
                    `${item?.municipality_from.name} a "${item?.center_to.name}" de ${item?.center_to.municipality}` +
                    ` https://destinodocente.es/compartir-coche/${item?.id}/${slugify(
                      item?.municipality_from.name +
                        " a " +
                        item?.center_to.name
                    )}`
                  }
                >
                  <span className="ms-2">WhatsApp</span>
                </ShareWSButton>
              </li>
              <li>
                <ShareFBButton
                  color="#1877f2"
                  size="28"
                  url={`https://destinodocente.es/compartir-coche/${item?.id}/${slugify(
                    item?.municipality_from.name + " a " + item?.center_to.name
                  )}`}
                  message={`¡Míralo en Destino Docente! Viaje de ${item?.municipality_from.name} a ${item?.center_to.name} de ${item?.center_to.municipality}`}
                >
                  <span className="ms-2">Facebook</span>
                </ShareFBButton>
              </li>
            </ul>
          </ShareMenu>,
        ]}
      >
        <Row>
          {/*
          <Col md={24}>
            {isMobile ? (
              <div className="text-center">
                <h6>
                  {item.municipality_from.name} (
                  {item.municipality_from.province})
                </h6>
                <FaArrowDown className="mt-1 mb-2" />
                <h6>
                  {denomAbrev[item.center_to.denom]} "{item.center_to.name}"
                  de {item.center_to.municipality} ({item.center_to.province})
                </h6>
              </div>
            ) : (
              <h6>
                {item.municipality_from.name} (
                {item.municipality_from.province}
                )
                <FaArrowRight className="mx-2" />
                {denomAbrev[item.center_to.denom]} "{item.center_to.name}" de{" "}
                {item.center_to.municipality} ({item.center_to.province})
              </h6>
            )}
          </Col>
          */}
          <Col>
            <Row gutter={[12, 12]} className="mt-3 col-xs-12">
              <Col>
                {item.schedule === IES_DENOM ? (
                  <>
                    <FaUniversity
                      size={iconSize}
                      style={{ color: "darkred" }}
                    />{" "}
                    Instituto
                  </>
                ) : (
                  <>
                    <FaSchool size={iconSize} style={{ color: "limegreen" }} />{" "}
                    Colegio
                  </>
                )}
              </Col>
              <Col>
                {item.travel_type === "C" ? (
                  <>
                    <FaCar size={iconSize} style={{ color: "limegreen" }} />{" "}
                    Coche
                  </>
                ) : (
                  <>
                    <FaWalking size={iconSize} style={{ color: "darkred" }} />{" "}
                    Sin Coche
                  </>
                )}
              </Col>
              <Col>
                <FaCalendarAlt size={iconSize - 2} />
                <span className="ms-1">
                  {item?.start_date
                    ? moment(item?.start_date).format("D/M/YY")
                    : ""}{" "}
                  a{" "}
                  {item?.end_date
                    ? moment(item?.end_date).format("D/M/YY")
                    : ""}
                </span>
              </Col>
              <Col>
                {item?.roadDuration && (
                  <>
                    <FaRoad size={iconSize} /> {item.roadDuration}
                  </>
                )}
              </Col>
              <Col></Col>
            </Row>
          </Col>
        </Row>
      </Card>
    </List.Item>
  );
};

function TravelSearchPage() {
  const [travels, setTravels] = useState([]);
  const [text, setText] = useState("");

  const fetchDataTravels = async () => {
    const params = [{ key: "api_key", value: api_key }];

    const queryString = buildQueryString(params);
    const uri = `${API_TRAVELS_URL}/search-travels/?${queryString}`;

    try {
      const response = await axios.get(uri);
      if (response.data) {
        setTravels(response.data);
      }
    } catch (error) {
      console.error("Hubo un error recuperar los datos", error);
    }
  };

  useEffect(() => {
    document.title = "Destino Docente. Busca viajes a tu destino docente";
  }, []);

  useEffect(() => {
    fetchDataTravels();

    const fetchDataChunk = async (chunk) => {
      try {
        const res = await getChunkByKey(chunk);
        setText(res.content);
      } catch (error) {
        console.log("Error fetching chunk");
      }
    };

    fetchDataChunk("TravelSearch");
  }, []);
  /*
  const afterTravelDelete = () => {
    fetchDataTravels();
  };
  */
  return (
    <>
      <Helmet>
        <link rel="canonical" href={CLIENT_SITE_URL + "/compartir-coche"} />
        <meta
          name="description"
          content="Comparte coche y ahorra en el viaje a tu centro."
        />
      </Helmet>
      <PageHeader />
      <SectionImage title={"¿A dónde quieres ir?"}>
        <TravelButton
          munCodeOrig={null}
          munNameOrig={null}
          centerDest={null}
          userId={getLocalUserId()}
          afterFinish={() => {
            fetchDataTravels();
          }}
        />
      </SectionImage>
      <div className="col-md-8 offset-md-2 col-xs-12 text-page">
        <div className={isMobile ? "mt-0" : "row my-4"}>
          <div className="col-md-5 col-xs-12">
            <Card
              style={{ border: "2px solid #7ebf8e" }}
              className={isMobile ? "my-4 mx-2 pt-4" : "pt-4"}
            >
              <TravelSearchForm setTravels={setTravels} />
            </Card>
            {renderAsDiv(text)}
          </div>
          <div className="col-md-7 col-xs-12">
            <div className={isMobile ? "mx-2 mb-4" : ""}>
              {!!travels ? (
                <List
                  className="item-list"
                  dataSource={travels}
                  renderItem={renderItem}
                  locale={{
                    emptyText: (
                      <Card
                        cover={
                          <img
                            alt="Destino Docente Viajes"
                            src={background}
                            height={310}
                          />
                        }
                      >
                        <Meta
                          title={
                            <div className="my-2 text-center">
                              ¡Vaya! No hay viajes publicados.
                            </div>
                          }
                          description={
                            <div className="text-center mt-3">
                              <TravelButton
                                munCodeOrig={null}
                                munNameOrig={null}
                                centerDest={null}
                                userId={getLocalUserId()}
                                afterFinish={() => {
                                  fetchDataTravels();
                                }}
                                text="Publica un viaje aquí"
                              />
                            </div>
                          }
                        />
                      </Card>
                    ),
                  }}
                />
              ) : (
                <>
                  <Skeleton active />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <PageFooter />
    </>
  );
}

export { TravelSearchPage, renderItem };
