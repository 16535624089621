import axiosAuth from "../utils/auth-interceptor";
import {
  API_MESSAGES_URL,
  trailing_key_param,
  trailing_key,
} from "../../constants";

// Función para obtener mensajes
export const fetchMessages = async (userId) => {
  const uri = `${API_MESSAGES_URL}/?userid=${userId}${trailing_key_param}`;
  try {
    const response = await axiosAuth.get(uri);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchConversation = async (senderId, recipientId) => {
  const uri = `${API_MESSAGES_URL}/get-conversation/?sender=${senderId}&recipient=${recipientId}${trailing_key_param}`;
  try {
    const response = await axiosAuth.get(uri);
    /*if (response.data.error || response.data.length === 0) {
      throw new Error("No messages received");
    }*/
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchConversations = async () => {
  const uri = `${API_MESSAGES_URL}/get-conversations/${trailing_key}`;
  try {
    const response = await axiosAuth.get(uri);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Función para enviar un nuevo mensaje
export const sendMessage = async (data) => {
  const uri = `${API_MESSAGES_URL}/create-by-params/${trailing_key}`;

  try {
    const response = await axiosAuth.post(uri, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};
