import React from "react";
import PageHeader from "../../layout/header";
import MessagesList from "../../components/messaging/message-list";
import SectionImage from "../../components/visual/section-image";
import logo from "../../assets/background.jpg";

function UserMessagesPage() {
  return (
    <>
      <PageHeader />
      <SectionImage src={logo} title={"Tus mensajes"} />
      <div className="col-md-8 offset-md-2 col-xs-12 text-page">
        <div className="my-4">
          <MessagesList />
        </div>
      </div>
    </>
  );
}

export default UserMessagesPage;
