import React from 'react';

function Legend() {
  return (
    <div className="mapa-leyenda">
      <div className="leyenda-item">
        <span className="leyenda-color leyenda-color-1"></span>
        <span className="leyenda-texto">{"<"} 20 min</span>
      </div>
      
      <div className="leyenda-item">
        <span className="leyenda-color leyenda-color-2"></span>
        <span className="leyenda-texto">{"<"} 40 min</span>
      </div>

      <div className="leyenda-item">
        <span className="leyenda-color leyenda-color-3"></span>
        <span className="leyenda-texto">{"<"} 60 min</span>
      </div>
      
      <div className="leyenda-item">
        <span className="leyenda-color leyenda-color-4"></span>
        <span className="leyenda-texto">{">"} 60 min</span>
      </div>
     
    </div>
  );
}

export default Legend;
