import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { List, Tabs, Skeleton, Card, Drawer, Button, notification } from "antd";
import { NavBar, Popup } from "antd-mobile";
import { BrowserView, MobileView } from "react-device-detect";
import { FaAngleRight } from "react-icons/fa";
import moment from "moment";

import SendMessageForm from "./message-form";
import SectionImage from "../visual/section-image";

import { fetchConversations } from "./messaging-services";
import { getLocalUserId } from "../../services/auth-services";
import { delay } from "../../utils/utils";
import logo from "../../assets/background.jpg";

const { Meta } = Card;

function MessagesList() {
  const listRef = useRef(null);
  let navigate = useNavigate();
  // TODO: el scroll no funciona en todas las conversaciones
  /*const listRefs = [];
  for (let i = 0; i < fetchedConversations.length; i++) {
    listRefs.push(useRef(null));
  }*/
  const [conversations, setConversations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [selectedConversation, setSelectedConversation] = useState(null);

  const getRecipient = (conversation) => {
    if (conversation) {
      const cArray = conversation.split("-");
      if (cArray[0] == getLocalUserId()) {
        return cArray[3];
      } else if (cArray[2] == getLocalUserId()) {
        return cArray[1];
      }
    }
  };

  const getRecipientId = (conversation) => {
    const cArray = conversation.split("-");
    if (cArray[0] == getLocalUserId()) {
      return cArray[2];
    } else if (cArray[2] == getLocalUserId()) {
      return cArray[0];
    }
  };

  const showDrawer = (conversation) => {
    setSelectedConversation(conversation);
    setVisible(true);
  };

  const onCloseDrawer = () => {
    setVisible(false);
  };

  useEffect(() => {
    const fetchConvers = async () => {
      try {
        setLoading(true);
        const fetchedConversations = await fetchConversations();
        setConversations(fetchedConversations);
      } catch (error) {
        if (error.response.status === 401) {
          const stateObj = { state: { from: window.location.pathname } };
          navigate("/salir", stateObj);
        } else {
          notification.warning({
            message: "Se ha producido un error",
          });
        }
      } finally {
        delay(1000);
        setLoading(false);
      }
    };

    fetchConvers();
  }, []);

  useEffect(() => {
    // Scroll hacia abajo al cargar o actualizar la lista
    if (listRef.current) {
      const listElement = listRef.current;
      listElement.scrollTop = listElement.scrollHeight;
    }
  }, [conversations]);

  return (
    <div>
      {!!conversations && Object.entries(conversations).length > 0 ? (
        <>
          <style type="text/css">
            {".ant-tabs-nav-list{background-color: #eee}"}
            {".ant-tabs-tab.ant-tabs-tab-active{background-color: #fff}"}
            {
              ".ant-tabs-ink-bar,.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{color: #26400C}"
            }
          </style>
          <BrowserView>
            <Card>
              <Tabs
                style={{ minHeight: "50vh" }}
                tabPosition={"left"}
                items={Object.entries(conversations).map(
                  ([conversationId, messages]) => {
                    return {
                      label: (
                        <div style={{ minWidth: "10vw", textAlign: "left" }}>
                          <b>{getRecipient(conversationId)}</b>
                        </div>
                      ),
                      key: conversationId,
                      children: (
                        <>
                          <div
                            ref={listRef}
                            className="overflow message-list rounded"
                            style={{ maxHeight: "50vh" }}
                          >
                            <List
                              key={conversationId}
                              className="conversation"
                              itemLayout="horizontal"
                              dataSource={messages}
                              locale={{
                                emptyText: "No hay mensajes que mostrar",
                              }}
                              renderItem={(item) => (
                                <List.Item
                                  className={`message ${
                                    item.sender == getLocalUserId()
                                      ? "sent"
                                      : "received"
                                  }`}
                                >
                                  <Skeleton
                                    avatar
                                    title={false}
                                    loading={loading}
                                    active
                                  >
                                    <List.Item.Meta
                                      description={
                                        <>
                                          <div>{item.content}</div>{" "}
                                          <div style={{ textAlign: "right" }}>
                                            <small>
                                              {moment(item.timestamp).format(
                                                "DD/MM/YYYY hh:mm"
                                              )}
                                            </small>
                                          </div>
                                        </>
                                      }
                                    />
                                  </Skeleton>
                                </List.Item>
                              )}
                            />
                          </div>
                          <Card
                            style={{ border: "2px solid #7ebf8e" }}
                            className="mt-3"
                          >
                            <SendMessageForm
                              senderId={getLocalUserId()}
                              recipientId={getRecipientId(conversationId)}
                              afterFinish={async () => {
                                const fetchedConversations =
                                  await fetchConversations();
                                setConversations(fetchedConversations);
                              }}
                              afterCancel={() => {
                                setConversations([]);
                              }}
                            />
                          </Card>
                        </>
                      ),
                    };
                  }
                )}
              ></Tabs>
            </Card>
          </BrowserView>

          <MobileView>
            <style type="text/css">
              {
                ".ant-drawer-header{background-color: #7ebf8e; color:#fff; padding:0  !important}"
              }
            </style>
            <>
              <List
                className="px-2"
                dataSource={Object.entries(conversations)}
                renderItem={([conversationId, messages]) => (
                  <List.Item
                    onClick={() => showDrawer({ conversationId, messages })}
                    actions={[
                      <Button
                        style={{ padding: 0, height: "100%" }}
                        type="button"
                      >
                        <FaAngleRight size={24} />
                      </Button>,
                    ]}
                  >
                    <List.Item.Meta
                      /*avatar={<FaUserCircle />}*/
                      title={<b>{getRecipient(conversationId)}</b>}
                    />
                  </List.Item>
                )}
              />

              <Popup
                visible={visible}
                getContainer={false} // para que funcione en mobile
                onMaskClick={() => {
                  onCloseDrawer();
                }}
                onCancel={() => {
                  onCloseDrawer();
                }}
                position="right"
                bodyStyle={{ width: "100%" }}
              >
                <NavBar
                  onBack={() => {
                    onCloseDrawer();
                  }}
                  className="back-bar"
                  style={{ height: "64px" }}
                >
                  {`${getRecipient(selectedConversation?.conversationId)}`}
                </NavBar>
                <div
                  className="overflow message-list"
                  style={{ maxHeight: "70vh" }}
                >
                  {selectedConversation && (
                    <List
                      key={selectedConversation.conversationId}
                      className="conversation"
                      itemLayout="horizontal"
                      dataSource={selectedConversation.messages}
                      renderItem={(item) => (
                        <List.Item
                          className={`message ${
                            item.sender == getLocalUserId()
                              ? "sent"
                              : "received"
                          }`}
                        >
                          <Skeleton
                            avatar
                            title={false}
                            loading={loading}
                            active
                          >
                            <List.Item.Meta
                              description={
                                <>
                                  <div>{item.content}</div>
                                  <div style={{ textAlign: "right" }}>
                                    <small>
                                      {moment(item.timestamp).format(
                                        "DD/MM/YYYY HH:mm"
                                      )}
                                    </small>
                                  </div>
                                </>
                              }
                            />
                          </Skeleton>
                        </List.Item>
                      )}
                    />
                  )}
                </div>
                {selectedConversation && (
                  <Card>
                    <SendMessageForm
                      senderId={getLocalUserId()}
                      recipientId={getRecipientId(
                        selectedConversation?.conversationId
                      )}
                      afterFinish={async () => {
                        const fetchedConversations = await fetchConversations();
                        setConversations(fetchedConversations);
                        onCloseDrawer();
                      }}
                      afterCancel={() => {
                        onCloseDrawer();
                      }}
                    />
                  </Card>
                )}
              </Popup>
            </>
          </MobileView>
        </>
      ) : (
        <Card
          cover={<SectionImage src={logo} showImage={true} height={"auto"} />}
        >
          <Meta
            title="No tienes mensajes."
            description="Podrás enviar y recibir mensajes al publicar tus viajes"
          />
        </Card>
      )}
    </div>
  );
}

export default MessagesList;
