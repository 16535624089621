import React, { useState, useEffect } from "react";

import { getLocalUserId } from "../../services/auth-services";
import { getChunkByKey } from "../../services/utils-services";
import { renderAsP } from "./chunk-page";
import PageHeader from "../../layout/header";
import SectionImage from "../../components/visual/section-image";
import PageFooter from "../../layout/footer";
import UserCommunity from "../../components/auth/user-community";

/**
 * Página de selección de comunidades
 * @param {*} props
 * @returns 
 */
function CommunitySelect() {
  const [text, setText] = useState("");

  useEffect(() => {
    const fetchDataChunk = async (chunk) => {
      try {
        const res = await getChunkByKey(chunk);
        setText(res.content);
      } catch (error) {
        console.log("Error fetching chunk");
      }
    };

    fetchDataChunk("CommunitySelect");
  }, []);

  return (
    <>
      <PageHeader />
      <SectionImage title={"Elige tus comunidades favoritas"} />
      <div>
        <div className="col-md-8 offset-md-2 col-xs-12 mt-4 text-page">
          <div>
            <div className="px-2">{renderAsP(text)}</div>
            <UserCommunity userId={getLocalUserId()} />
          </div>
        </div>
      </div>
      <PageFooter />
    </>
  );
}

export default CommunitySelect;
