// fichero de claves
export const _ORS_KEY = "5b3ce3597851110001cf62481654a58fec2b49e3bd74c31658299895";
export const _G_API_KEY = "AIzaSyCy4V5uCFDg7bTMErKDcXJ1Z_w2AQTC_Nk";
export const _G_MAPS_API_KEY = "AIzaSyAQhi55mwUYK1v2QH10Fe0clvojclRRHg0";

export const _DD_API_KEY = "ZGVzdGlub2RvY2VudGUuZXM=";

export const _G_oauth_CLIENT_ID = "636767603022-m919r1ik9iq6nqek4jthaqg0tm5g05q9.apps.googleusercontent.com"
export const _BUGSNAG = "71b5799cadb17e1cb0ef52a5a02672de"
/*import config from 'config'

export const SITE_URL = config.get('SITE_URL');
export const API_CENTER_URL = SITE_URL + "/centers/api";
export const API_COUNTRY_URL = SITE_URL + "/countries/api";

export const ORS_KEY = config.get('ORS_KEY');
export const G_API_KEY = config.get('G_API_KEY');
export const G_MAPS_API_KEY = config.get('G_MAPS_API_KEY');
export const G_MAPS_MATRIX_API_KEY = config.get('G_MAPS_MATRIX_API_KEY');
*/
