import React, { useState, useEffect, useRef } from "react";
import { Menu, Button, Badge } from "antd";
import {
  FaUserCircle,
  FaEnvelope,
  FaTaxi,
  FaQuestionCircle,
  FaSignOutAlt,
  FaBuilding,
} from "react-icons/fa";
import {
  getLocalUserFirstName,
  isAuth,
  getLocalUserId,
} from "../../services/auth-services";
import { fetchMessages } from "../messaging/messaging-services";

const UserMenu = (props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [messages, setMessages] = useState([]);

  const menuRef = useRef();
  const buttonRef = useRef();

  useEffect(() => {
    setIsMenuOpen(false);

    const loadMessages = async () => {
      try {
        const fetchedMessages = await fetchMessages(getLocalUserId());
        setMessages(fetchedMessages);
      } catch (error) {
        console.log(error);
      }
    };
    /*
    if (getLocalUserId()) {
      loadMessages();
    }
    
    // Configura un intervalo para llamar a loadMessages cada minuto
    const interval = setInterval(() => {
      if (isAuth()) {
        loadMessages();
      }
    }, 60000); // 60000 ms = 1 minuto

    // Limpia el intervalo cuando el componente se desmonte
    return () => clearInterval(interval);
    */
  }, []);

  const handleMenuClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleClickOutside = (event) => {
    /*if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsMenuOpen(false);
    }*/
    if (
      menuRef.current &&
      !menuRef.current.contains(event.target) &&
      buttonRef.current &&
      !buttonRef.current.contains(event.target)
    ) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    if (isMenuOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMenuOpen]);

  return (
    <div className={props.className}>
      {isMenuOpen && (
        <div ref={menuRef}>
          <Menu
            mode="vertical"
            style={{
              position: "absolute",
              right: 10,
              top: 64,
              marginTop: props.marginTop,
              zIndex: 2000,
              width: 175,
              border: "1px solid gray",
            }}
            className="rounded"
          >
            {!isAuth() && (
              <>
                <Menu.Item key="1" className="px-2">
                  <a href="/entrar">Iniciar Sesión</a>
                </Menu.Item>
              </>
            )}
            {!!isAuth() && (
              <>
                <Menu.Item key="1" className="px-2">
                  <a href="/tus-mensajes">
                    <FaEnvelope /> Tus mensajes{" "}
                    <Badge count={messages.length} />
                  </a>
                </Menu.Item>
                <Menu.Item key="2" className="px-2">
                  <a href="/tus-viajes">
                    <FaTaxi /> Tus viajes
                  </a>
                </Menu.Item>
                <Menu.Item key="3" className="px-2 d-none">
                  <a href="/tus-puestos"> Tus puestos</a>
                </Menu.Item>
                <Menu.Item key="3" className="px-2">
                  <a href="/tus-anuncios">
                    <FaBuilding /> Tus anuncios
                  </a>
                </Menu.Item>
                <Menu.Item key="4" className="px-2">
                  <a href="/tu-cuenta">
                    <FaUserCircle /> Tu cuenta
                  </a>
                </Menu.Item>
                <Menu.Item key="10" className="px-2">
                  <a href="/contacto">
                    <FaQuestionCircle /> Ayuda
                  </a>
                </Menu.Item>
                <hr />
                <Menu.Item key="12" className="px-2">
                  <a href="/salir">
                    <FaSignOutAlt /> Cerrar Sesión
                  </a>
                </Menu.Item>
              </>
            )}
          </Menu>
        </div>
      )}
      <Button
        ref={buttonRef}
        type="button"
        className="btn-action-user"
        /*
        icon={
          <Badge count={messages.length}>
            <FaUserCircle size={"32"} />
          </Badge>
        }
        */
        onClick={handleMenuClick}
      >
        <span className="ellipsis" style={{ fontSize: "1rem" }}>
          Hola {getLocalUserFirstName()}
        </span>
      </Button>
    </div>
  );
};

export default UserMenu;
