export function getItemsStartingWith(prefix) {
  return Object.keys(localStorage)
    .filter((key) => key.startsWith(prefix))
    .reduce((result, key) => {
      const rr = JSON.parse(localStorage.getItem(key));
      result[key] = rr;
      return result;
    }, {});
}

export function clearStorageByKey_(key) {
  if (key) {
    const keysToDelete = Object.keys(localStorage).filter((key) =>
      key.startsWith(key)
    );
    keysToDelete.forEach((key) => {
      localStorage.removeItem(key);
    });
  }
}

export function clearStorageByKey(prefix) {
  const keys = Object.keys(localStorage);
  const keysToDelete = keys.filter(key => key.startsWith(prefix));
  keysToDelete.forEach(key => localStorage.removeItem(key));
}

