import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { Row, Col, Card, Image } from "antd";
import { NavBar } from "antd-mobile";
import {
  FaUsers,
  FaHouseUser,
  FaBuilding,
  FaBed,
  FaBath,
} from "react-icons/fa";
import slugify from "react-slugify";
import moment from "moment/moment";
import ShareMenu from "../../components/visual/share-menu";
import { ShareFBButton, ShareWSButton } from "../../utils/utils";
import MessageButton from "../../components/messaging/message-button";
import FavButton from "../../components/visual/fav";
import { API_ADS_URL, trailing_key } from "../../constants";
import { getAdTitle } from "../../utils/utils";
import { getLocalUserId, isAuth } from "../../services/auth-services";
import { denomAbrev } from "../../constants/data";

function AdInfo() {
  const { adId: pAdId, adName: pAdName } = useParams();
  const [item, setItem] = useState(null);
  let navigate = useNavigate();

  const fetchData = (id) => {
    const uri = `${API_ADS_URL}/${id}/${trailing_key}`;
    if (uri) {
      axios
        .get(uri)
        .then((response) => {
          if (response.data) {
            setItem(response.data);
          }
        })
        .catch((error) => {
          // Verificar si el error es un error de respuesta HTTP y si el código es 404
          if (error.response && error.response.status === 404) {
            console.error("Error 404: No encontrado.");
          } else {
            console.error("Error al verificar el anuncio", error);
          }
          setItem(null);
        });
    }
  };

  const back = () => {
    const isDirectAccess =
      window.performance.getEntriesByType("navigation")[0].type === "navigate";

    if (isDirectAccess) {
      navigate("/compartir-casa");
    } else {
      navigate(-1);
    }
  };

  useEffect(() => {
    if (pAdId) fetchData(pAdId);
  }, [pAdId]);

  return (
    <>
      <NavBar onBack={back} className="back-bar" style={{ height: "64px" }}>
        {item && (
          <>
            <h5>
              {item.ad_type === "H" ? (
                <FaHouseUser
                  size="18"
                  style={{ marginTop: "-2px", color: "LimeGreen" }}
                />
              ) : (
                <FaUsers
                  size="16"
                  style={{ marginTop: "-2px", color: "darkred" }}
                />
              )}
              <span className="ms-2">{getAdTitle(item)}</span>
            </h5>
          </>
        )}
      </NavBar>
      <div className="row">
        <div className="col-md-8 offset-md-2 col-xs-12 text-page">
          {item ? (
            <Card
              style={{ width: "100%" }}
              actions={
                item.creator.id != getLocalUserId() && [
                  <MessageButton
                    senderId={getLocalUserId()}
                    recipientId={item.creator.id}
                  >
                    <span className="ms-2">Contactar</span>
                  </MessageButton>,
                  isAuth() && (
                    <FavButton contentTypeId={44} objectId={item.id} />
                  ),
                ]
              }
              extra={[
                <ShareMenu title="Comparte este anuncio">
                  <ul className="share-menu">
                    <li key={item.id + "-1"} className="py-2">
                      <ShareWSButton
                        color="rgb(0,168,132)"
                        size="28"
                        message={
                          "¡Míralo en en Destino Docente! " +
                          `${getAdTitle(item)}` +
                          ` https://destinodocente.es/compartir-casa/${
                            item?.id
                          }/${slugify(getAdTitle(item))}`
                        }
                      >
                        <span className="ms-2">WhatsApp</span>
                      </ShareWSButton>
                    </li>
                    <li key={item.id + "-2"} className="py-2">
                      <ShareFBButton
                        color="#1877f2"
                        size="26"
                        url={`https://destinodocente.es/compartir-casa/${
                          item?.id
                        }/${slugify(getAdTitle(item))}`}
                        message={`¡Míralo en Destino Docente! ${getAdTitle(
                          item
                        )} `}
                      >
                        <span className="ms-2">Facebook</span>
                      </ShareFBButton>
                    </li>
                  </ul>
                </ShareMenu>,
              ]}
              cover={
                <>
                  {item.images && item.images.length > 0 ? (
                    <Image.PreviewGroup
                      preview={{
                        onChange: (current, prev) =>
                          console.log(
                            `current index: ${current}, prev index: ${prev}`
                          ),
                      }}
                    >
                      {item.images.map((image) => (
                        <Image
                          key={image.id}
                          src={`${image.image}`}
                          alt="Foto del anuncio"
                          style={{
                            maxHeight: "300px",
                            width: "100%",
                            objectFit: "cover",
                            marginBottom: "10px",
                          }}
                        />
                      ))}
                    </Image.PreviewGroup>
                  ) : (
                    <div
                      className="py-4 text-center"
                      style={{ backgroundColor: "#efefef" }}
                    >
                      <FaBuilding
                        size="155px"
                        style={{
                          color: "#aaaaaa",
                        }}
                      />
                    </div>
                  )}
                </>
              }
            >
              <Row gutter={[8, 8]} className="mt-2">
                {!!item.price && item.price > 0 && (
                  <Col md={24} xs={24}>
                    <h5>{Math.round(item.price).toString()} €</h5>
                  </Col>
                )}
                {!!item.description && (
                  <Col md={24} xs={24}>
                    <p>{item.description}</p>
                  </Col>
                )}
                {!!item.n_rooms && (
                  <Col md={4} xs={4}>
                    <FaBed size={"20"} /> {item.n_rooms}
                  </Col>
                )}
                {!!item.n_baths && (
                  <Col md={4} xs={4}>
                    <FaBath size={"18"} /> {item.n_baths}
                  </Col>
                )}
                {!!item.centers && item.centers.length > 0 && (
                  <Col md={16} xs={24}>
                    <i className="fa fa-map-marker " aria-hidden="true" />
                    {" Cerca de " +
                      denomAbrev[item.centers[0].denom] +
                      " " +
                      item.centers[0].name}
                  </Col>
                )}
              </Row>
              {item.creator.id == getLocalUserId() && (
                <Row gutter={[8, 8]}>
                  <Col md={24} xs={24}>
                    <div className="pull-right">
                      <i>
                        {item?.created
                          ? moment(item?.created).format("D/M/YY")
                          : ""}
                      </i>
                    </div>
                  </Col>
                </Row>
              )}
            </Card>
          ) : (
            <>No encontrado</>
          )}
        </div>
      </div>
    </>
  );
}

export default AdInfo;
