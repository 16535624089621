import React from "react";
import { Layout } from "antd";
import { FaSignInAlt } from "react-icons/fa";

import MainMenu from "../components/visual/main-menu";
import UserMenu from "../components/visual/user-menu";
import { isAuth } from "../services/auth-services";
import logo from "../assets/logo.png";

const { Header } = Layout;

const PageHeader = () => {
  return (
    <Header className="header">
      <MainMenu className="pull-left mx-2" marginTop={64} />
      <div className="ms-2 pull-left">
        <a href="/">
          <img
            src={logo}
            className="logo"
            alt="Logo Destino Docente. Encuentra destinos docentes cercanos a ti y comparte coche"
            height={45}
            width={110}
          />
        </a>
      </div>
      <div className="user-menu">
        {!!isAuth() ? (
          <UserMenu />
        ) : (
          <a href="/entrar" className="btn-action-user me-2">
            <span style={{ fontSize: "1rem" }}>
              Iniciar sesión
            </span>{" "}
            <FaSignInAlt style={{ marginTop: "-5px" }}/>
          </a>
        )}
      </div>
    </Header>
  );
};

export default PageHeader;
