import React, { useState, useEffect } from "react";

import { Select } from "antd";
import axios from "axios";
import { API_CENTER_URL, trailing_key } from "../../constants";
import { denomAbrev } from "../../constants/data";

function CenterSelect(props) {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      let uri = API_CENTER_URL + "/centers";

      if (props.munCode) uri += `/mun_code/${props.munCode}`;
      if (props.denom) uri += `/denom/${props.denom}`;
      uri += trailing_key;
      await axios
        .get(uri, {
          timeout: 5000,
        })
        .then((response) => {
          if (response.data.length > 0) {
            const options = response.data.map(
              (record) =>
                !!record.name && {
                  value: record.name,
                  label: (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                      key={record.code}
                    >
                      <span>
                        {!!denomAbrev[record.denom]
                          ? denomAbrev[record.denom]
                          : record.denom}{" "}
                        {record.name}, {record.city} ({record.province})
                      </span>
                      <span className="coords d-none">
                        {[record.longitude, record.latitude]}
                      </span>
                      <span className="code d-none">{record.code}</span>
                    </div>
                  ),
                }
            );
            setOptions(options);
          }
        })
        .catch((error) => {
          setOptions([]);
          console.error(error);
        });
    };
    if (props.munCode || props.denom) fetchData();
  }, [props.munCode, props.denom]);

  return (
    <>
      <Select
        showSearch
        optionFilterProp="children"
        onSelect={props.onSelectCenters}
        filterOption={(input, option) => {
          const value = option?.value;
          return typeof value === "string" && value.toLowerCase().includes(input.toLowerCase());
        }}
        options={options}
        disabled={props.disabled}
        allowClear={true}
        onClear={props.onClearCenters}
        value={props.defaultValue}
        placeholder={
          props.placeHolder !== undefined
            ? props.placeHolder
            : "Escribe un nombre de centro"
        }
      />
    </>
  );
}

export default CenterSelect;
