import React, { useEffect, useRef, useState } from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { FaPhone, FaAt } from "react-icons/fa";
import { MapContainer, TileLayer, Marker, Popup, Circle } from "react-leaflet";
import { useMap } from "react-leaflet";
import L from "leaflet";
import { divIcon } from "leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import { Link } from "react-router-dom";
import { FaCrosshairs } from "react-icons/fa";

import CenterDistance from "../components/center-distance-local.js";
import CenterPositions from "./center-positions-local.js";
import TravelButton from "./travels/travel-button.js";
//import StreetView from "../components/street-view.js";
import icoStraight from "../assets/straight-line.svg";
import Legend from "./map-legend.js";
import { denomAbrev } from "../constants/data.js";
import { getLocalUserId } from "../services/auth-services.js";

import "leaflet/dist/leaflet.css";

const munIconMarkup = renderToStaticMarkup(
  <i className="fa fa-map-pin fa-2x mun-marker" aria-hidden="true" />
);

const munMarkerIcon = divIcon({
  html: munIconMarkup,
});

const markerPopUp = (marker, munCode, coords, aproxCoords) => {
  return (
    <Popup maxWidth="340">
      <div className="marker-text">
        <h6>
          {marker.code} - {!!marker.denom ? denomAbrev[marker.denom] : ""} "
          {marker.name}"
        </h6>
        {/* 
        <StreetView coords={[marker.latitude, marker.longitude]} />
        */}
        {(!!marker?.province || !!marker?.city) && (
          <>
            <i className="fa fa-map-signs" aria-hidden="true"></i>
            {"  "}
            {marker.address}.{"  "}
            {marker.zip_code} <b>{marker.city}</b>, {marker.province}
            <br />
          </>
        )}

        {!!marker?.email && (
          <>
            <FaAt />
            {"  "}
            <a
              href={
                "mailto:" +
                marker?.email +
                "?body=Encontrado en destinodocente.es"
              }
              marker="_blank"
              rel="noreferrer"
            >
              {marker?.email}
            </a>
            <br />
          </>
        )}
        {!!marker?.phone && (
          <>
            <FaPhone />
            {"  "}
            <a
              href={"callto:" + marker?.phone}
              target="_blank"
              rel="noreferrer"
            >
              {marker?.phone}
            </a>
            <br />
          </>
        )}
        {!!marker.distance && (
          <span style={{ paddingRight: ".5rem" }}>
            <img
              src={icoStraight}
              width="20px"
              height="20px"
              alt="distancia línea recta"
            />{" "}
            {Math.round(marker.distance)} Km
          </span>
        )}
        <CenterDistance
          idCenter={marker.id}
          centerCoords={[marker.latitude, marker.longitude]}
          munCode={munCode}
          munCoords={coords}
          showInfo={true}
          aproxCoords={aproxCoords}
        />
        <div style={{ overflowY: "scroll" }} className="mt-4">
          <CenterPositions idCenter={marker.id} marker={marker} />
        </div>
        <div className="text-center my-4 d-none">
          <TravelButton
            munCodeOrig={null}
            munNameOrig={null}
            centerDest={marker}
            userId={getLocalUserId()}
          />
        </div>
      </div>
    </Popup>
  );
};

function Map(props) {
  const mapRef = useRef(null);
  const circleRef = useRef();

  const [showCenter, setShowCenter] = useState(false);
  const [zoom, setZoom] = useState(6);

  useEffect(() => {
    setShowCenter(!!props.showCenter ? true : false);
  }, [props.showCenter]);

  // fit to circle center
  useEffect(() => {
    if (props.coords !== undefined && mapRef !== undefined && mapRef.current) {
      const centerLatLng = L.latLng(props.coords);
      const bounds = centerLatLng.toBounds(props.radius * 1000);
      mapRef.current.fitBounds(bounds);
      //setShowCenter(true);
    } /*else {
      setShowCenter(false);
    }*/
  }, [props.coords, props.radius, mapRef]);

  // fit to circle bounds
  useEffect(() => {
    if (
      props.coords !== undefined &&
      circleRef !== undefined &&
      circleRef.current
    ) {
      const bounds = circleRef.current.getBounds();
      mapRef.current.fitBounds(bounds);
      //setShowCenter(true);
    } /*else {
      setShowCenter(false);
    }*/
  }, [props.coords, props.radius, circleRef]);

  // fit to markers
  useEffect(() => {
    if (props.radius === null && props.markers?.length > 0 && mapRef.current) {
      const bounds = L.latLngBounds(
        props.markers.map((marker) => [marker.latitude, marker.longitude])
      );
      mapRef.current.fitBounds(bounds);
    }
  }, [props.markers, mapRef]);

  /**
   * Fuerza el recentrado del mapa
   * @param {*} param0
   * @returns
   */
  const Recenter = ({ coords, radius }) => {
    const map = useMap();
    if (coords && radius) {
      const centerLatLng = L.latLng(coords);
      const bounds = centerLatLng.toBounds((radius + radius / 10) * 2000);
      map.fitBounds(bounds);
    }
    return null;
  };

  return (
    <>
      <Link to="/centros/cercanos" className="mapa-btn">
        <FaCrosshairs size="14" />
      </Link>
      <MapContainer
        ref={mapRef}
        center={!!props.coords ? props.coords : props.centerCoords}
        zoom={zoom}
        style={{ height: "100%", width: "100%" }}
        scrollWheelZoom={false}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        <Recenter coords={props.coords} radius={props.radius} />
        {!!showCenter && (
          <Marker
            position={[
              parseFloat(props.coords[0]),
              parseFloat(props.coords[1]),
            ]}
            key={"centermark"}
            icon={munMarkerIcon}
            alt="Posición Central"
          ></Marker>
        )}
        {!props.clustered ? (
          props.markers?.map((marker) => (
            <Marker
              position={[marker.latitude, marker.longitude]}
              key={"mark_" + marker.id}
              icon={divIcon({
                html: renderToStaticMarkup(
                  <i
                    className={
                      "fa fa-map-marker center-marker marker-" + marker.id
                    }
                    aria-hidden="true"
                    id={"marker_" + marker.id}
                  />
                ),
              })}
              alt={marker.name}
            >
              {markerPopUp(
                marker,
                props.munCode,
                props.coords,
                props.aproxCoords
              )}
            </Marker>
          ))
        ) : (
          <MarkerClusterGroup showCoverageOnHover={false} maxClusterRadius={50}>
            {props.markers.map((marker, index) => (
              <Marker
                key={index}
                position={[marker.latitude, marker.longitude]}
                title={marker.name}
                icon={divIcon({
                  html: renderToStaticMarkup(
                    <i
                      className="fa fa-map-marker center-marker"
                      aria-hidden="true"
                      id={"marker_" + marker.id}
                    />
                  ),
                })}
                alt={marker.name}
              >
                {markerPopUp(marker, props.munCode, props.coords)}
              </Marker>
            ))}
          </MarkerClusterGroup>
        )}

        {!!props.coords && !!props.radius && (
          <>
            <Legend />
            <Circle
              ref={circleRef}
              center={props.coords}
              // radius={(props.radius + props.radius / 10) * 1000} // le sumo 10% x 1000 metros
              radius={props.radius * 1050}
              color={"#7EBF8E"}
              fillColor={"#7EBF8E"}
            />
          </>
        )}
      </MapContainer>
    </>
  );
}
//});

export default Map;
