import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Tooltip, Card } from "antd";

import PageFooter from "../layout/footer";
import SectionImage from "../components/visual/section-image";

import { getChunkByKey } from "../services/utils-services";
import { renderAsDiv } from "../pages/portal/chunk-page";
import EmbeddedContent from "./embed";

/**
 * Pagina de redireccionamiento a otra pagina
 * @returns
 */
function RedirectPage() {
  const [text, setText] = useState("");
  const [url, setUrl] = useState("");
  const [seconds, setSeconds] = useState(10); // Nuevo estado para el contador
  const [searchParams] = useSearchParams();
  
  useEffect(() => {
    document.title =
      "Destino Docente. Todos tus destinos de secundaria y primaria a un solo clic";

    const fetchDataChunk = async (chunk) => {
      try {
        const res = await getChunkByKey(chunk);
        setText(res.content);
      } catch (error) {
        console.log("Error fetching chunk");
      }
    };

    fetchDataChunk("redirect");
  }, []);

  useEffect(() => {
    setUrl(searchParams.get("url"));
  }, [searchParams]);

  useEffect(() => {
    // Solo inicia el temporizador si la URL existe
    if (url) {
      // Establece un temporizador que cuenta regresivamente cada segundo
      const intervalId = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);

      // Redirecciona cuando el contador llega a 0
      const timer = setTimeout(() => {
        window.location.href = url;
      }, 10000);

      // Limpieza: detiene el intervalo y el temporizador al desmontar
      return () => {
        clearInterval(intervalId);
        clearTimeout(timer);
      };
    }
  }, [url]);

  // Mostrar el contador al usuario
  useEffect(() => {
    if (seconds <= 0) {
      clearInterval(); // Detiene el intervalo cuando el contador llega a 0
    }
  }, [seconds]);

  return (
    <>
      <SectionImage title="Redirección a otra página" />
      <div>
        <div className="col-md-8 offset-md-2 col-xs-12 text-page my-2">
          <Card
            cover={<EmbeddedContent background={true} />}
            className="m-2"
          >
            <h5>
              Para continuar, pulsa el botón o espera {seconds}{" "}
              segundos
            </h5>
            <div className="mt-2">
              {!!url && (
                <>
                  <div className="text-center mt-4">
                    <Tooltip title={"Ir a " + url}>
                      <a
                        className="btn btn-default"
                        href={url}
                        rel="noreferrer"
                      >
                        Continuar a la página
                      </a>
                    </Tooltip>

                    <div
                      className="mt-2 d-none"
                      style={{
                        textOverflow: "ellipsis",
                        width: "200px",
                      }}
                    >
                      <small>{url}</small>
                    </div>
                  </div>
                </>
              )}
              <br />
              {renderAsDiv(text)}
            </div>
          </Card>
        </div>
      </div>
      <PageFooter />
    </>
  );
}

export default RedirectPage;
