import React, { useState, useEffect } from "react";
import axios from "axios";
import { AutoComplete, Input, Spin } from "antd";
import { SearchOutlined, LoadingOutlined } from "@ant-design/icons";
import { API_COUNTRY_URL, trailing_key } from "../constants";

function MunicipalityAutocomplete(props) {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState(props.defaultValue);

  useEffect(() => {
    // Actualiza el valor del input cuando initialValue cambia
    setInputValue(props.defaultValue);
  }, [props.defaultValue]);

  /**
   * Búsqueda API externa
   * @param {*} value
   */
  const handleMunicipalitiesSearch_ext = async (value) => {
    if (value.length > 2) {
      setLoading(true);
      // const uri = `https://public.opendatasoft.com/api/records/1.0/search/?dataset=georef-spain-municipio&q=${value}&facet=mun_namee&facet=munCode&exclude.acom_code=02&exclude.acom_code=03&exclude.acom_code=04&exclude.acom_code=05&exclude.acom_code=06&exclude.acom_code=07&exclude.acom_code=08&exclude.acom_code=09&exclude.acom_code=10&exclude.acom_code=11&exclude.acom_code=12&exclude.acom_code=13&exclude.acom_code=15&exclude.acom_code=16&exclude.acom_code=17&exclude.acom_code=18&exclude.acom_code=19&exclude.acom_code=20`;
      // const uri = `https://public.opendatasoft.com/api/records/1.0/search/?dataset=georef-spain-municipio&q=${value}&facet=mun_namee&facet=munCode&exclude.acom_code=02&exclude.acom_code=03&exclude.acom_code=04&exclude.acom_code=05&exclude.acom_code=06&exclude.acom_code=07&exclude.acom_code=08&exclude.acom_code=09&exclude.acom_code=10&exclude.acom_code=11&exclude.acom_code=12&exclude.acom_code=15&exclude.acom_code=16&exclude.acom_code=17&exclude.acom_code=18&exclude.acom_code=19&exclude.acom_code=20`;
      // const uri = `https://public.opendatasoft.com/api/records/1.0/search/?dataset=georef-spain-municipio&q=${value}&facet=mun_namee&facet=munCode&exclude.acom_code=02&exclude.acom_code=03&exclude.acom_code=04&exclude.acom_code=05&exclude.acom_code=06&exclude.acom_code=08&exclude.acom_code=09&exclude.acom_code=10&exclude.acom_code=11&exclude.acom_code=12&exclude.acom_code=15&exclude.acom_code=16&exclude.acom_code=17&exclude.acom_code=18&exclude.acom_code=19&exclude.acom_code=20`;
      // Aragón
      // const uri = `https://public.opendatasoft.com/api/records/1.0/search/?dataset=georef-spain-municipio&q=${value}&facet=mun_namee&facet=munCode&exclude.acom_code=03&exclude.acom_code=04&exclude.acom_code=05&exclude.acom_code=06&exclude.acom_code=08&exclude.acom_code=09&exclude.acom_code=10&exclude.acom_code=11&exclude.acom_code=12&exclude.acom_code=15&exclude.acom_code=16&exclude.acom_code=17&exclude.acom_code=18&exclude.acom_code=19&exclude.acom_code=20`;
      // valencia
      // const uri = `https://public.opendatasoft.com/api/records/1.0/search/?dataset=georef-spain-municipio&q=${value}&facet=mun_namee&facet=munCode&exclude.acom_code=03&exclude.acom_code=04&exclude.acom_code=05&exclude.acom_code=06&exclude.acom_code=08&exclude.acom_code=09&exclude.acom_code=11&exclude.acom_code=12&exclude.acom_code=15&exclude.acom_code=16&exclude.acom_code=17&exclude.acom_code=18&exclude.acom_code=19&exclude.acom_code=20`;
      // Galicia
      // const uri = `https://public.opendatasoft.com/api/records/1.0/search/?dataset=georef-spain-municipio&q=${value}&facet=mun_namee&facet=munCode&exclude.acom_code=03&exclude.acom_code=04&exclude.acom_code=05&exclude.acom_code=06&exclude.acom_code=08&exclude.acom_code=09&exclude.acom_code=11&exclude.acom_code=15&exclude.acom_code=16&exclude.acom_code=17&exclude.acom_code=18&exclude.acom_code=19&exclude.acom_code=20`;
      // CLM
      const uri = `https://public.opendatasoft.com/api/records/1.0/search/?dataset=georef-spain-municipio&q=${value}&facet=mun_namee&facet=munCode&exclude.acom_code=03&exclude.acom_code=04&exclude.acom_code=05&exclude.acom_code=06&exclude.acom_code=09&exclude.acom_code=11&exclude.acom_code=15&exclude.acom_code=16&exclude.acom_code=17&exclude.acom_code=18&exclude.acom_code=19&exclude.acom_code=20`;

      await axios
        .get(uri, {
          timeout: 5000,
        })
        .then((response) => {
          const options = response.data.records.map((record) => ({
            value: record.fields.mun_name,
            label: (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <span>
                  {record.fields.mun_name} ({record.fields.prov_name})
                </span>
                <span className="coords d-none">
                  {record.geometry.coordinates}
                </span>
                <span className="code d-none">{record.fields.mun_code}</span>
              </div>
            ),
          }));
          setOptions(options);
          if (options.length === 0) {
            setOptions([
              {
                disabled: true,
                value: null,
                label: (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>No se ha encontrado</span>
                  </div>
                ),
              },
            ]);
          }
        })
        .catch((error) => {
          // intento en mi Backend
          handleMunicipalitiesSearch(value);
          /*
          setOptions([
            {
              disabled: true,
              value: "",
              label: (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <span>No se ha encontrado el municipio.</span>
                </div>
              ),
            },
          ]);*/
        });
    } else {
      setOptions([]);
    }
    setLoading(false);
  };

  /**
   * Búsqueda en Backend
   * @param {*} value
   */
  const handleMunicipalitiesSearch = async (value) => {
    const uri = API_COUNTRY_URL + `/municipalities/${value}` + trailing_key;
    if (value.length > 2) {
      setLoading(true);
      await axios
        .get(uri, {
          timeout: 5000,
        })
        .then((response) => {
          if (response.data.length > 0) {
            const options = response.data.map((record) => ({
              value: record.name,
              label: (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <span>
                    {record.name} ({record.province})
                  </span>
                  <span className="coords d-none">
                    {[record.longitude, record.latitude]}
                  </span>
                  <span className="code d-none">{record.code}</span>
                </div>
              ),
            }));
            setOptions(options);
          } else {
            setOptions([
              {
                disabled: true,
                value: "",
                label: (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>No se ha encontrado el municipio</span>
                  </div>
                ),
              },
            ]);
          }
        })
        .catch((error) => {
          setOptions([
            {
              disabled: true,
              value: "",
              label: (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <span>No se ha encontrado el municipio</span>
                </div>
              ),
            },
          ]);
        });
    } else {
      setOptions([]);
    }
    setLoading(false);
  };

  return (
    <>
      <AutoComplete
        autoFocus={props.autofocus}
        options={options}
        onSelect={props.onSelectMunicipalities}
        onSearch={handleMunicipalitiesSearch_ext}
        //defaultValue={props.defaultValue}
        value={inputValue}
        onChange={setInputValue}
        allowClear={true}
        onClear={props.onClearMunicipalities}
        disabled={props.disabled}
        notFoundContent={
          loading ? (
            <Spin
              indicator={
                <LoadingOutlined
                  style={{
                    fontSize: 18,
                  }}
                />
              }
            />
          ) : null
        }
      >
        <Input
          prefix={
            <>
              <SearchOutlined className="me-2" /> {props.iconSuffix}
            </>
          }
          placeholder={
            props.placeHolder !== undefined
              ? props.placeHolder
              : "Escribe un nombre de municipio"
          }
          size={props.size ? props.size : ""}
        />
      </AutoComplete>
    </>
  );
}

export default MunicipalityAutocomplete;
