import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { FaPhone, FaAt, FaDirections, FaCrosshairs } from "react-icons/fa";
import { NavBar } from "antd-mobile";
import { Skeleton, Card, Button } from "antd";
import { Helmet } from "react-helmet";
import slugify from "react-slugify";
//import StreetView from "../../components/street-view.js";
import CenterMap from "../../components/center-map.js";
//import CenterInitialPositions from "../../components/center-positions-initial.js";
import CenterTempPositions from "../../components/center-positions-temporary.js";
//import CenterTrasladosPositions from "../../components/center-positions-tralados.js";
import TravelButton from "../../components/travels/travel-button.js";
import LoginButton from "../../components/auth/login-button.js";
import PageHeader from "../../layout/header.js";
import PageFooter from "../../layout/footer.js";
import SectionImage from "../../components/visual/section-image.js";
import AdsMun from "../../components/ads/ads-mun.js";
import {
  ShareFBButton,
  ShareWSButton,
  getValueLabel,
  deslugify,
  processUrlCentro,
  capitalize,
} from "../../utils/utils.js";
import { denomAbrev, optionsDenom } from "../../constants/data.js";
import { getCenterByCode } from "../../services/center-services.js";
import { isAuth, getLocalUserId } from "../../services/auth-services.js";
import { CLIENT_SITE_URL } from "../../constants/index.js";

const { Meta } = Card;

/**
 * Página de infromación de un centro
 * @returns
 */
function CenterInfo() {
  //  parámetros en url
  const { codCenter: pCodCenter, nameCenter: pNameCenter } = useParams();
  const [center, setCenter] = useState();
  const [centerAux, setCenterAux] = useState();
  const [error, setError] = useState(false);

  let navigate = useNavigate();

  useEffect(() => {
    const fetchData = async (codCenter) => {
      try {
        const response = await getCenterByCode(codCenter);
        setCenter(response);
      } catch (error) {
        setError(true);
        setCenterAux(processUrlCentro(`/centros/${pCodCenter}/${pNameCenter}`));
      }
    };
    fetchData(pCodCenter);
  }, [pCodCenter]);

  useEffect(() => {
    document.title = !!center
      ? "Destino Docente. " +
        //center?.code +
        // " -  " +
        //center?.denom +
        denomAbrev[center?.denom] +
        " " +
        center?.name +
        " de " +
        center?.city
      : deslugify(pNameCenter);
  }, [center]);

  const back = () => {
    const isDirectAccess =
      window.performance.getEntriesByType("navigation")[0].type === "navigate";

    if (isDirectAccess) {
      navigate("/");
    } else {
      navigate(-1);
    }
  };

  return (
    <>
      <Helmet>
        <link
          rel="canonical"
          href={
            !!center && center.absolute_url
              ? `${CLIENT_SITE_URL}/centros${center.absolute_url}`
              : `${CLIENT_SITE_URL}/centros/${pCodCenter}/${pNameCenter}`
          }
        />
        <meta
          name="keywords"
          content={`Destino docente, instituto, colegio, centro educativo, ${
            center
              ? `${center.denom} ${center.name}, ${denomAbrev[center.denom]} ${
                  center.name
                }`
              : deslugify(pNameCenter)
          }`}
        />
        <meta
          name="description"
          content={
            !!center
              ? `Destino docente: cómo llegar a ${center.denom} "${center.name}" de ${center?.city}, ${center?.province}. Encuentra centros
              cerca de ${center?.municipality}`
              : "Ahorra tiempo encontrando centros para tu solicitud de destino"
          }
        />
        <meta
          name="twitter:description"
          content="Ahorra tiempo encontrando centros para tu solicitud de destino"
        />
        <meta
          name="twitter:image"
          content="https://destinodocente.es/logo_comp.jpg"
        />
        <meta
          name="twitter:domain"
          content={`${CLIENT_SITE_URL}/centros${center?.absolute_url}`}
        />
        <meta
          property="og:title"
          content={
            "Destino Docente. " +
            center?.code +
            " -  " +
            denomAbrev[center?.denom] +
            " " +
            center?.name +
            ". " +
            center?.city
          }
        />
        <meta
          property="og:description"
          content="Ahorra tiempo encontrando centros para tu solicitud de destino"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content={`${CLIENT_SITE_URL}/centros${center?.absolute_url}`}
        />
        <meta
          property="og:image"
          content="https://destinodocente.es/logo_comp.jpg"
        />
      </Helmet>
      <NavBar
        onBack={back}
        className="back-bar d-none"
        style={{ height: "64px" }}
      >
        {denomAbrev[center?.denom]} {center?.name} de {center?.city}
      </NavBar>
      <PageHeader />
      <SectionImage
        title={
          !!center ? (
            <>
              {denomAbrev[center?.denom]} {center?.name} de {center?.city},{" "}
              {center?.province}
            </>
          ) : (
            <>{deslugify(pNameCenter)}</>
          )
        }
      />
      <div>
        <div className="col-md-8 offset-md-2 col-xs-12 text-page">
          {/*
          <StreetView coords={[center?.latitude, center?.longitude]} />
          */}
          {!!center ? (
            <div>
              <Card
                className="my-4"
                actions={[
                  <>
                    <TravelButton
                      munCodeOrig={null}
                      munNameOrig={null}
                      centerDest={center}
                      userId={getLocalUserId()}
                      iconSize={36}
                      className="me-1"
                      title="Publica un viaje a este centro"
                    />
                    {!!center.latitude && !!center.longitude && (
                      <LoginButton
                        title="Mostrar la ruta en Google Maps"
                        className="me-1 p-0"
                        from={
                          "/redirect?url=https://www.google.es/maps/dir/" +
                          [center?.latitude, center?.longitude] +
                          "/" +
                          center?.latitude +
                          "," +
                          center?.longitude +
                          "?travelmode=driving"
                        }
                      >
                        <FaDirections size={28} style={{ color: "#26400C" }} />
                      </LoginButton>
                    )}
                    <ShareWSButton
                      color="rgb(0,168,132)"
                      size="34"
                      message={
                        "¡Échale un vistazo en Destino Docente! " +
                        `${center?.denom} "${center?.name}"` +
                        ` https://destinodocente.es/centros${center?.absolute_url}`
                      }
                      className="me-1"
                    />
                    <ShareFBButton
                      color="#1877f2"
                      size="32"
                      url={`https://destinodocente.es/centros${center?.absolute_url}`}
                      message={
                        "¡Échale un vistazo en Destino Docente! " +
                        `${center?.denom} "${center?.name}"`
                      }
                    />
                  </>,
                ]}
                cover={
                  <div style={{ height: "200px" }}>
                    <CenterMap coords={[center?.latitude, center?.longitude]} />
                  </div>
                }
                extra={
                  <>
                    {/*
                    <a
                      href={`/centros/${center?.latitude}/${
                        center?.longitude
                      }/${slugify(center?.municipality)}/${
                        center?.municipality_code
                      }/${center?.denom_id}/${slugify(
                        getValueLabel(optionsDenom, center?.denom_id)
                      )}/radio/10`}
                      style={{ display: "inline" }}
                    >
                      <FaCrosshairs size={22} className="me-2" /> Más centros
                      cerca de {center?.municipality}
                    </a>
                    */}

                    <Button
                      onClick={() =>
                        navigate(
                          `/centros/${center?.latitude}/${
                            center?.longitude
                          }/${slugify(center?.municipality)}/${
                            center?.municipality_code
                          }/${center?.denom_id}/${slugify(
                            getValueLabel(optionsDenom, center?.denom_id)
                          )}/radio/10`
                        )
                      }
                      className="no-border p-0"
                    >
                      <FaCrosshairs size={22} className="me-2" /> Más centros
                      cerca de {center?.municipality}
                    </Button>
                  </>
                }
              >
                <Meta
                  title={
                    <p>
                      {!!isAuth() && (
                        <>
                          <i className="fa fa-map-signs" aria-hidden="true"></i>{" "}
                          {center?.address}
                          <br />
                        </>
                      )}
                      {center?.zip_code} <b>{center?.city}</b>
                      {center?.city !== center?.municipality &&
                        " (" + center?.municipality + ")"}
                      <br /> {center?.province}
                      {/*
                      <a
                        href={`/centros/lista/${center?.region_id}/${slugify(
                          center?.region
                        )}/provincia/${center?.province_code}/${slugify(
                          center?.province
                        )}/denom/${center?.denom_id}/${slugify(center?.denom)}`}
                      >
                        Ver más centros de la provincia de {center?.province}{" "}
                      </a>
                      */}
                    </p>
                  }
                  description={
                    <>
                      {!!isAuth() && (
                        <p>
                          <FaAt />
                          {"  "}
                          <a
                            href={
                              "mailto:" +
                              center?.email +
                              "?body=Encontrado en destinodocente.es"
                            }
                            target="_blank"
                            rel="noreferrer"
                          >
                            <i>{center?.email}</i>
                          </a>
                          <br />
                          <FaPhone />
                          {"  "}
                          <a
                            href={"callto:" + center?.phone}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <small>{center?.phone}</small>
                          </a>
                        </p>
                      )}
                      Código de centro: {center?.code}
                      <br />
                      <Button
                        onClick={() =>
                          navigate(
                            `/centros/lista/${center?.region_id}/${slugify(
                              center?.region
                            )}/provincia/${center?.province_code}/${slugify(
                              center?.province
                            )}/denom/${center?.denom_id}/${slugify(
                              center?.denom
                            )}`
                          )
                        }
                        className="no-border p-0 pull-right"
                      >
                        Más centros de la provincia de {center?.province}{" "}
                      </Button>
                    </>
                  }
                />
              </Card>
              <Card>
                <CenterTempPositions code={center?.code} />
                {/*
                <TravelsMunCenter
                  munCodeOrig={null}
                  munCodeDest={center?.municipality__code}
                  centerCodeDest={center?.code}
                  schedule={center?.denom__id}
                />
                <div className="row">
                  <div className="col-xs-12 col-md-6 mt-3">
                    <CenterTrasladosPositions code={center?.code} />
                  </div>
                  <div className="col-xs-12 col-md-6 mt-3">
                    <CenterInitialPositions code={center?.code} />
                  </div>
                </div>
                */}
              </Card>
              <AdsMun munCode={center?.municipality_code} />
            </div>
          ) : (
            <div className="text-center my-4">
              {!error ? (
                <>
                  <Card
                    cover={
                      <div className="text-center mt-4">
                        <Skeleton.Node active>
                          <i
                            className="fa fa-map-marker fa-3x center-marker"
                            aria-hidden="true"
                          />
                        </Skeleton.Node>
                      </div>
                    }
                  >
                    <Skeleton className="p-3 mt-3" active />
                  </Card>
                </>
              ) : (
                <>
                  {pCodCenter} {centerAux?.denom} {capitalize(centerAux?.name)}{" "}
                  de <b>{capitalize(centerAux?.city)}</b>
                  <br /> {capitalize(centerAux?.province)}
                </>
              )}
            </div>
          )}
        </div>
      </div>
      <PageFooter />
    </>
  );
}

export default CenterInfo;
