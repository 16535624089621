export const createCenterDistance = (
  munCode,
  idCenter,
  municipalityCoordinates,
  centerCoordinates,
  straightDistance,
  roadDistance,
  travelTime,
  source
) => {
  return {
    munCode,
    idCenter,
    coordinates: {
      municipality: municipalityCoordinates,
      center: centerCoordinates,
      origin: municipalityCoordinates
    },
    distance: {
      straight: straightDistance,
      road: roadDistance,
      travelTime,
    },
    source: source,
    timestamp: Date.now()
  };
};

// no usado
export const createCenterDistanceFromOrigin = (
  originCoordinates,
  idCenter,
  centerCoordinates,
  straightDistance,
  roadDistance,
  travelTime,
  source
) => {
  return {
    idCenter,
    coordinates: {
      origin: originCoordinates,
      center: centerCoordinates,
    },
    distance: {
      straight: straightDistance,
      road: roadDistance,
      travelTime,
    },
    source: source,
    timestamp: Date.now()
  };
};


export const createCenterLatestPositions = (
  idCenter,
  npositions,
  position,
  type,
  participation,
  endDate,
  date,
  observations,
  centro_latitude,
  centro_longitude,
  centro_name,
  centro_denom,
  centro_city,
  centro_province,
  centro_code
) => {
  return {
    idCenter,
    npositions: npositions,
    position: position,
    type: type,
    participation: participation,
    endDate: endDate,
    date: date,
    observations:observations,
    timestamp: Date.now(),
    centro_latitude: centro_latitude,
    centro_longitude: centro_longitude,
    centro_name: centro_name,
    centro_denom: centro_denom,
    centro_city: centro_city,
    centro_province: centro_province,
    centro_code: centro_code
  };
};
