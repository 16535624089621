import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Form, Layout, Statistic, Row, Col } from "antd";
import { Helmet } from "react-helmet";
import slugify from "react-slugify";
import { FaTaxi, FaBuilding } from "react-icons/fa";

import MunicipalityAutocomplete from "../../components/municipality-autocomplete";
import PageFooter from "../../layout/footer";
import PageHeader from "../../layout/header";
import LatestPositions from "../../components/positions/latest-plazas";
import Notices from "../../components/visual/notices";
import MapaComunidades from "../../components/visual/comm-map";
import EmbeddedContent from "../../utils/embed";
import { renderAsP } from "./chunk-page";
import { getChunkByKey, getMunByCode } from "../../services/utils-services";
import { CLIENT_SITE_URL } from "../../constants";

//import backgroundTravel from "../../assets/background-travel.jpg";
//import backgroundAds from "../../assets/background-ad.jpg";

const { Content } = Layout;

function Home() {
  let navigate = useNavigate();
  const [text, setText] = useState("");

  useEffect(() => {
    document.title =
      "Destino Docente. Todos tus destinos de secundaria y primaria a un solo clic";

    const fetchDataChunk = async (chunk) => {
      try {
        const res = await getChunkByKey(chunk);
        setText(res.content);
      } catch (error) {
        console.log("Error fetching chunk");
      }
    };

    fetchDataChunk("home");
  }, []);

  /*
  const onSelectMunicipalities_ = (value, item) => {
    if (value !== undefined) {
      const children = item.label.props.children;
      let coordinates = children[1].props.children;
      const munCode = children[2].props.children;

      // sacar las coord de mi BD
      getMunByCode(munCode)
        .then((data) => {
          if (data) {
            coordinates = [data.longitude, data.latitude];
            navigate(
              "/centros/" +
                data.longitude +
                "/" +
                data.latitude +
                "/" +
                encodeURI(value) +
                "/" +
                munCode
            );
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });

      navigate(
        "/centros/" +
          coordinates[1] +
          "/" +
          coordinates[0] +
          "/" +
          encodeURI(value) +
          "/" +
          munCode
      );
    }
  };
  */

  const onSelectMunicipalities = async (value, item) => {
    if (value === undefined) {
      return;
    }

    const children = item.label.props.children;
    let coordinates = children[1].props.children;
    const munCode = children[2].props.children;
    let path = `/centros/${coordinates[1]}/${coordinates[0]}/${slugify(
      value
    )}/${munCode}`;

    // afino coords con mi BD de emunicipios
    try {
      const data = await getMunByCode(munCode);
      if (data) {
        coordinates = [data.longitude, data.latitude];
        path = `/centros/${coordinates[1]}/${coordinates[0]}/${slugify(
          value
        )}/${munCode}`;
      }
    } catch (error) {
      console.error("Error:", error);
    }

    navigate(path);
  };

  const onClearMunicipalities = () => {};

  return (
    <>
      <Helmet>
        <link rel="canonical" href={CLIENT_SITE_URL} />
        <meta
          name="description"
          content="Tiempos de viaje de tu localidad a destinos docentes de secundaria y primaria en 
                Andalucía, Aragón, Castilla y León, Castilla-La Mancha, Galicia, Madrid, Murcia y Comunidad Valenciana.
                Comparte coche y encuentra piso o habitación para alquilar o compartir"
        />
        <meta property="og:title" content="Destino Docente" />
        <meta
          property="og:description"
          content="Ahorra tiempo encontrando centros para tu solicitud de destino"
        />
        <meta
          property="og:image"
          content="https://destinodocente.es/logo_comp.jpg"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://destinodocente.es" />

        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={"Destino Docente"} />
        <meta
          name="twitter:description"
          content="Ahorra tiempo encontrando centros para tu solicitud de destino"
        />
        <meta
          name="twitter:image"
          content="https://destinodocente.es/logo_comp.jpg"
        />
        <meta name="twitter:domain" content="https://destinodocente.es" />
      </Helmet>
      <Notices />
      <PageHeader />
      <Content>
        <div className="jumbotron-wrapper jumbotron-wrapper-tint mb-4">
          <div className="container">
            <div className="jumbotron home-wrapper">
              <div
                className="jumbotron-inner"
                style={{ backgroundColor: "transparent" }}
              >
                <div className="px-4">
                  <h1 style={{ fontSize: "1.33rem", color: "white" }}>
                    <b>Ahorra tiempo</b> encontrando centros para tu{" "}
                    <b>solicitud de destino</b> en un solo clic
                  </h1>
                </div>
                <div className="search-form-main-wrapper">
                  <Form name="search-form-main">
                    <Form.Item name="origin">
                      <MunicipalityAutocomplete
                        autoFocus={false}
                        onSelectMunicipalities={onSelectMunicipalities}
                        placeHolder="Escribe el municipio desde donde quieres buscar"
                        className="search-form-main-input"
                        fullOnclick={true}
                        onClearMunicipalities={onClearMunicipalities}
                        size="large"
                      />
                    </Form.Item>
                  </Form>
                  <br></br>
                  <Link
                    to="/centros/cercanos"
                    className="underlined text-white mt-4"
                  >
                    <h5>o encuentra destinos cerca de tí</h5>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="jumbotron my-4">
          <div className="col-md-8 col-xs-11 offset-md-2">
            {renderAsP(text)}
          </div>
        </div>

        {/*
        <div
          className="my-4"
          style={{
            height: "280px",
            display: "flex",
          }}
        >
          <div
            className="col-md-4 col-xs-12 offset-md-2 px-4"
            style={{
              backgroundColor: "#7EBF8E",
              height: "100%",
            }}
          >
            <Badge.Ribbon
              placement="start"
              text={<span>¡Nuevo!</span>}
              color="rgba(246, 9, 9, 0.8)"
              style={{ marginLeft: "-1.5rem" }}
            />
            <span className="lead text-white">
              <br />
              <h3 className="mt-3">Comparte coche con Destino Docente</h3>
              Encuentra viajes a tu centro y ahorra compartiendo.
              <br />
            </span>
            <div className="text-center my-3">
              <Link to="/compartir-coche" className="btn btn-callto px-4 py-2">
                <span className="px-4">Encuentra tu viaje</span>
              </Link>
            </div>
          </div>
          <div
            className="col-md-4 col-xs-12 p-0 m-0 d-none d-sm-block"
            style={{ height: "100%" }}
          >
            <img
              alt="Comparte coche con Destino Docente"
              src={backgroundTravel}
              style={{ width: "98%", height: "100%" }}
            />
          </div>
        </div>
        */}

        <div className="col-md-8 col-xs-12 offset-md-2">
          <div className="row">
            <div className="col-md-6 col-xs-12 px-4 mt-4">
              <div className="text-center">
                <FaTaxi
                  size="52px"
                  style={{
                    color: "#7EBF8E",
                  }}
                />
              </div>
              <h4 className="mt-3">Comparte coche con Destino Docente</h4>
              <span className="hightlight">
                Encuentra viajes a tu centro y ahorra compartiendo coche o si no
                tienes coche encuentra viajes a tu centro.
              </span>
              <div className="my-4 btn-callto-wrapper">
                <Link to="/compartir-coche" className="btn btn-callto">
                  ¿A dónde quiers ir?
                </Link>
              </div>
            </div>

            <div className="col-md-6 col-xs-12 px-4 mt-4">
              <div className="text-center">
                <FaBuilding
                  size="52px"
                  style={{
                    color: "#7EBF8E",
                  }}
                />
              </div>
              <h4 className="mt-3">Busca casa con Destino Docente</h4>
              <span className="hightlight">
                Encuentra casa, piso o habitación cerca de tu centro o publica
                tu anuncio si tienes alojamiento para alquilar.
              </span>
              <div className="my-4 btn-callto-wrapper">
                <Link to="/compartir-casa" className="btn btn-callto">
                  ¿Buscas casa?
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="d-none d-sm-block">&nbsp;</div>

        <div className="jumbotron my-4 row">
          <div className="col-md-8 col-xs-11 offset-md-2">
            <h3>¿Qué destinos docentes puedes encontrar?</h3>
            <Row gutter={8} className="my-2">
              <Col span={24}>
                <Statistic title="" value={12145 + " centros públicos"} />
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={12}>
                <Statistic title="Centros de Primaria" value={5665} />
              </Col>
              <Col span={12}>
                <Statistic title="Centros de Secundaria" value={2431} />
              </Col>
            </Row>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-md-8 col-xs-11 offset-md-2">
            <p className="strong px-4">
              de Andalucía, Aragón, Castilla-La Mancha, Castilla y León,
              Comunidad de Madrid, Comunidad Valenciana, Galicia y Región de
              Murcia.
            </p>
            <div className="my-4 d-none-d-sm-block">
              <center>
                <MapaComunidades />
              </center>
            </div>
            <p className="mb-4 px-4">
              ¿No está tu comunidad? Trabajamos para incorporar todos los
              <b> centros docentes de España</b>.<br></br>
              <a href="/contacto" className="underlined">
                Contacta con nosotros
              </a>{" "}
              para incorporar tu comunidad autónoma.
            </p>
          </div>
        </div>

        <div className=" d-none d-sm-block">&nbsp;</div>

        <div className="my-2" id="vacantes-andalucia">
          <LatestPositions />
        </div>

        <div className="my-2">&nbsp;</div>

        <div className="col-md-8 col-xs-11 offset-md-2">
          <EmbeddedContent />
        </div>

        <div className="my-2 d-sm-block">&nbsp;</div>
      </Content>
      <PageFooter extraLinks={true} />
    </>
  );
}

export default Home;
