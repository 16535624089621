import React, { useState, useEffect } from "react";
import axios from "axios";
import { Form, Col, Row, Button, Radio } from "antd";
import { Slider } from "antd-mobile";
import {
  FaHouseUser,
  FaUsers,
  FaBuilding,
  FaDoorClosed,
} from "react-icons/fa";
import { buildQueryString, delay } from "../../utils/utils";
import { api_key, API_ADS_URL } from "../../constants";
import { priceMarks } from "../../constants/data";
import MunicipalityAutocomplete from "../municipality-autocomplete";
import { ResetFilterIcon } from "../visual/load-icon";

const AdSearchForm = (props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [munCode, setMunCode] = useState(null);
  const [maxPrice, setMaxPrice] = useState(null);
  const [adType, setAdType] = useState(null);
  const [adSubType, setAdSubType] = useState(null);

  const getResults = async () => {
    const params = [
      { key: "muncode", value: munCode },
      { key: "maxPrice", value: maxPrice },
      { key: "adType", value: adType },
      { key: "adSubType", value: adSubType },
      { key: "api_key", value: api_key },
    ];

    const queryString = buildQueryString(params);
    const uri = `${API_ADS_URL}/search-ads/?${queryString}`;

    try {
      const response = await axios.get(uri);
      if (response.data) {
        props.setAds(response.data);
      }
    } catch (error) {
      console.error("Hubo un error al recuperar los datos", error);
    }
  };

  useEffect(() => {
    //if (munCode === null && maxPrice === null && adType === null && adSubType === null) {
    getResults();
    //}
  }, [munCode, maxPrice, adType, adSubType]);

  const onFinish = async (e) => {
    setLoading(true);
    await getResults();
    setLoading(false);
  };

  const onReset = async () => {
    setMunCode(null);
    setMaxPrice(null);
    setAdType(null);
    setAdSubType(null);
    form.resetFields();
  };

  const onSelectMunicipalities = (value, item) => {
    if (value !== undefined) {
      const children = item.label.props.children;
      const munCode = children[2].props.children;
      setMunCode(munCode);
    }
  };

  const onClearMunicipalities = () => {
    setMunCode(null);
    form.setFieldsValue({ mun_code: null });
  };

  const onChangeType = (e) => {
    setAdType(e.target.value);
  };

  const onChangePrice = (value) => {
    setMaxPrice(value);
  };

  const onChangeSubType = (e) => {
    setAdSubType(e.target.value);
  };

  return (
    <Form form={form} layout="vertical" onFinish={onFinish}>
      <Row gutter={[4, 4]}>
        <Col md={24} xs={24}></Col>
      </Row>
      <Row gutter={[4, 4]}>
        <Col md={24} xs={24}>
          <Form.Item name="mun_code">
            <MunicipalityAutocomplete
              autoFocus={false}
              onSelectMunicipalities={onSelectMunicipalities}
              onClearMunicipalities={onClearMunicipalities}
              placeHolder="Escribe el municipio donde buscar"
              className="search-form-main-input"
              fullOnclick={true}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[4, 4]}>
        <Col md={24} xs={24}>
          <Form.Item name="maxPrice" label="Precio máximo">
            <Slider
              onChange={onChangePrice}
              marks={priceMarks}
              step={null}
              max={400}
              min={100}
              style={{
                "--fill-color": "#26400C",
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[4, 4]}>
        <Col md={24} xs={24}>
          <Form.Item name="adType">
            <Radio.Group
              onChange={onChangeType}
              style={{ width: "100%" }}
              className="radio-button"
            >
              <div style={{ display: "flex", width: "100%" }}>
                <Radio.Button
                  style={{ flex: 1, textAlign: "center" }}
                  value={"H"}
                >
                  <FaHouseUser /> Alquilar
                </Radio.Button>
                <Radio.Button
                  style={{ flex: 1, textAlign: "center" }}
                  value={"N"}
                >
                  <FaUsers /> Busco
                </Radio.Button>
              </div>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col md={24} xs={24}>
          <Form.Item name="adSubType">
            <Radio.Group
              value={adSubType}
              onChange={onChangeSubType}
              style={{ width: "100%" }}
              className="radio-button"
            >
              <div style={{ display: "flex", width: "100%" }}>
                <Radio.Button
                  value="R"
                  style={{
                    flex: 1,
                    display: "block",
                    textAlign: "center",
                    paddingInline: "5px",
                  }}
                >
                  <FaDoorClosed />
                  Habitación
                </Radio.Button>
                <Radio.Button
                  value="F"
                  style={{
                    flex: 1,
                    display: "block",
                    textAlign: "center",
                    paddingInline: "5px",
                  }}
                >
                  <FaBuilding /> Piso
                </Radio.Button>
                <Radio.Button
                  value="H"
                  style={{
                    flex: 1,
                    display: "block",
                    textAlign: "center",
                    paddingInline: "5px",
                  }}
                >
                  <FaHouseUser /> Casa
                </Radio.Button>
              </div>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[8, 8]} className="d-none">
        <Col md={24} xs={24}>
          <hr
            className="mt-1"
            style={{
              border: "2px solid #7ebf8e",
              width: "100%",
              height: "1px",
            }}
          />
          <Form.Item className="mb-0">
            <Button className="pull-left" onClick={onReset} ghost>
              <ResetFilterIcon />
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              className="pull-right"
              loading={loading}
              style={{ paddingLeft: "100px", paddingRight: "100px" }}
            >
              Buscar
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default AdSearchForm;
