import React, { useState, useEffect } from "react";

import RegistrationForm from "../../forms/register-form";
import { renderAsP } from "../portal/chunk-page";
import { getChunkByKey } from "../../services/utils-services";
import GoogleLoginComponent from "../../components/auth/google-login";

import "bootstrap/dist/css/bootstrap.min.css";
import "../../App.css";
import logo from "../../assets/logo.png";

function RegistrationPage() {
  const [text, setText] = useState("");

  useEffect(() => {
    document.title = "Destino Docente. Registro de nuevo usuario";
    const fetchDataChunk = async (chunk) => {
      try {
        const res = await getChunkByKey(chunk);
        setText(res.content);
      } catch (error) {
        console.log("Error fetching chunk");
      }
    };
    fetchDataChunk("register");
  }, []);

  return (
    <div className="col-xs-12 jumbotron-wrapper">
      <div className="container">
        <br />
        <div className="jumbotron">
          <div className="jumbotron-inner rounded">
            <a href="/">
              <img
                src={logo}
                className="img img-responsive inner-logo"
                alt="Destino Docente logo"
              />
            </a>
            <div className="row">
              <div className="col-xs-12 col-md-5">
                <h3>Crea tu cuenta gratuita</h3>
                <p>
                  Si ya tienes cuenta, inicia sesión{" "}
                  <a href="/entrar">
                    <b>aquí</b>
                  </a>
                </p>
                {renderAsP(text)}
              </div>
              <div className="col-xs-12 col-md-7">
                <div className="px-4 mt-2">
                  <RegistrationForm />
                </div>
                <p className="mt-1 small d-none">
                  Si no tienes cuenta, puedes registrarte con tu{" "}
                  <b>cuenta Google.</b>
                </p>
                <center className="d-none">
                  <GoogleLoginComponent />
                </center>
              </div>
            </div>
            <div className="mt-2 mb-4 small">
              <hr />
              Al seguir utilizando destinodocente.es, aceptas nuestras Términos
              del servicio y Política de privacidad.<br></br>
            </div>
          </div>
        </div>
        <br />
      </div>
    </div>
  );
}

export default RegistrationPage;
