import React, { useState } from "react";
import { useNavigate } from "react-router";
import { Button, notification } from "antd";
import { FaTrash } from "react-icons/fa";

import { trailing_key, API_ACCOUNTS_URL } from "../../constants";
import axiosAuth from "../utils/auth-interceptor";

const UserDeleteComponent = ({ userId }) => {
  let navigate = useNavigate();
  const [isConfirming, setIsConfirming] = useState(false);

  const handleDelete = () => {
    const uri = API_ACCOUNTS_URL + `/user/delete/${userId}` + trailing_key;
    axiosAuth
      .delete(uri)
      .then(() => {
        notification.success({ message: "Cuenta eliminada correctamente" });
        localStorage.clear();
        navigate("/");
      })
      .catch((error) => {
        notification.error({ message: "No se ha podido eliminar la cuenta" });
      });
  };

  return (
    <>
      {isConfirming ? (
        <div>
          <p className="small">
            ¿Quieres ELIMINAR tu cuenta? Esta acción no se puede deshacer.
          </p>
          <Button onClick={handleDelete} className="danger" danger>
            SÍ, ELIMINAR
          </Button>
          <Button onClick={() => setIsConfirming(false)} className="mx-3">
            Cancelar
          </Button>
        </div>
      ) : (
        <Button className="danger" danger onClick={() => setIsConfirming(true)}>
          <FaTrash className="me-2" /> Eliminar mi cuenta
        </Button>
      )}
    </>
  );
};

export default UserDeleteComponent;
