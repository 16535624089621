import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Modal, notification } from "antd";
import { ExclamationCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import { FaArrowDown } from "react-icons/fa";

import { API_TRAVELS_URL, trailing_key } from "../../constants";
import axiosAuth from "../utils/auth-interceptor";
import { isAuth } from "../../services/auth-services";

const { confirm } = Modal;

const TravelDeleteButton = (props) => {
  //const [travel, setTravel] = useState(null);
  //const [loading, setLoading] = useState(false);
  let navigate = useNavigate();

  const onClickDelete = () => {
    confirm({
      title: <div className="ps-4 ms-4">¿Quieres eliminar este viaje?</div>,
      icon: <ExclamationCircleOutlined size={props.iconSize}/>,
      content: (
        <div className="text-center pt-2 pb-4">
          {props.travel?.municipality_from.name} <br/> <FaArrowDown />{" "}
          <br/>
          {props.travel?.center_to.denom} "{props.travel?.center_to.name}" de{" "}
          {props.travel?.center_to.municipality}
        </div>
      ),
      onOk: () => handleDelete(),
      onCancel: () => Modal.destroyAll(),
      footer: [
        <div className="text-center">
          <Button onClick={() => Modal.destroyAll()}>No</Button>
          <Button onClick={handleDelete} className="danger ms-2" danger>
            SÍ
          </Button>
        </div>,
      ],
    });
  };

  const handleDelete = async () => {
    try {
      const uri = `${API_TRAVELS_URL}/auth/${props.travel.id}/${trailing_key}`;
      const response = await axiosAuth.delete(uri);
      if (response) {
        notification.success({
          message: "El viaje se ha eliminado correctamente",
        });
        props.afterFinish();
        Modal.destroyAll();
      }
    } catch (error) {
      if (error.response.status === 401) {
        notification.warning({
          message: "Tienes que iniciar de nuevo la sesión",
        });
        const stateObj = { state: { from: window.location.pathname } };
        navigate("/salir", stateObj);
      } else {
        notification.warning({
          message: "Error al eliminar el viaje",
        });
      }
    }
  };

  return (
    <>
      {!!isAuth() && props.travel && (
        <Button
  
          onClick={onClickDelete}
        >
          <DeleteOutlined />

        </Button>
      )}
    </>
  );
};

export default TravelDeleteButton;
