import { Selector } from "antd-mobile";

/**
 * Selector antd con estilos css propios
 * @param {*} props 
 * @returns 
 */
const CustomSelector = (props) => {
  return (
    <>
      <Selector
        onChange={props.onChange}
        options={props.options}
        multiple={props.multiple}
        showCheckMark={false}
        style={{
          //"--border-radius": "100px",
          "--border": "solid transparent 1px",
          "--checked-border": "solid var(--adm-color-primary) 1px",
          "--padding": "8px 24px",
          "--color": "#EBF2EE",
          "--checked-color": "#7ebf8e",
          "--checked-text-color": "white",
          "--checked-border": "solid #7ebf8e 1px",
        }}
      />
    </>
  );
};

export default CustomSelector;
