import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Button, notification, Skeleton, Card } from "antd";

import { API_ACCOUNTS_URL, trailing_key } from "../../constants";
import { getUserById } from "../../services/auth-services";
import axiosAuth from "../utils/auth-interceptor";
import { optionRegions } from "../../constants/data";
import { convertCodesToOptions } from "../../utils/utils";
import CustomSelector from "../visual/custom-selector";

/**
 * Componente de seleccion de CCAA de usuario
 * @param {*} param0 
 * @returns 
 */
const UserCommunity = ({ userId }) => {
  const [user, setUser] = useState(null);

  let navigate = useNavigate();

  useEffect(() => {
    const fetchData = async (userId) => {
      try {
        const res = await getUserById(userId);
        setUser(res);
      } catch (error) {
        if (error.response.status === 401) {
          const stateObj = { state: { from: window.location.pathname } };
          navigate("/salir", stateObj);
        } else {
          notification.warning({
            message: "Se ha producido un error",
          });
        }
      }
    };

    fetchData(userId);
  }, [userId]);

  const onChangeRegions = (value) => {
    user.regions = value;
  };

  const handleSubmit = () => {
    const uri = API_ACCOUNTS_URL + `/user/${userId}` + trailing_key;
    axiosAuth
      .put(uri, user)
      .then((response) => {
        setUser(response.data);
        notification.success({
          message: "Datos actualizados correctamente",
        });
        navigate("/");
      })
      .catch((error) => {
        notification.error({
          message: "Se ha producido un error al actualizar los datos",
        });
      });
  };

  return (
    <>
      {!!user ? (
        <Card>
          <label className="mb-4">
            {user?.first_name}, elige entre las comunidades disponibles
          </label>
          <Form
            onSubmit={handleSubmit}
            fields={[
              {
                name: ["regions"],
                value: !!user?.regions
                  ? convertCodesToOptions(user?.regions, optionRegions)
                  : [],
              },
            ]}
          >
            <Form.Item name="regions">
              <CustomSelector
                onChange={onChangeRegions}
                options={optionRegions}
                multiple={true}
              />
            </Form.Item>
            <div className="pull-right mt-4">
              <Button type="primary" onClick={() => handleSubmit()}>
                Guardar
              </Button>
            </div>
          </Form>
        </Card>
      ) : (
        <>
          <Skeleton className="p-3 mt-3" active />
        </>
      )}
    </>
  );
};

export default UserCommunity;
