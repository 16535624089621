import React, { useState, useEffect } from "react";
import axiosAuth from "../utils/auth-interceptor";
import { HeartOutlined, HeartFilled } from "@ant-design/icons";
import { Button, Tooltip, notification } from "antd";

import {
  API_FAVS_URL,
  trailing_key,
  trailing_key_param,
} from "../../constants";
import { getLocalUserId } from "../../services/auth-services";

const FavButton = ({ contentTypeId, objectId, title, className }) => {
  const [isFav, setIsFav] = useState(false);

  useEffect(() => {
    const uri =
      API_FAVS_URL +
      `/auth/is_fav/?content_type=${contentTypeId}&object_id=${objectId}` +
      trailing_key_param;
    axiosAuth
      .get(uri)
      .then((response) => {
        setIsFav(response.data.is_fav);
      })
      .catch((error) => console.error("Error:", error));
  }, [contentTypeId, objectId]);

  const toggleFav = () => {
    const uri = API_FAVS_URL + "/auth/toggle/" + trailing_key;
    axiosAuth
      .post(uri, {
        content_type: contentTypeId,
        object_id: objectId,
        user_id: getLocalUserId(),
      })
      .then((response) => {
        setIsFav(response.status === 201); // Si el estado es 201, es fav
        if (response.status === 201)
          notification.success({
            message: "Anuncio añadido a favoritos",
          });
      })
      .catch((error) => console.error("Error:", error));
  };

  return (
    <Tooltip title={title}>
      <Button
        onClick={toggleFav}
        type="text" // Usa "text" para eliminar el fondo y el borde
        style={{
          padding: 0, // Elimina el padding interno
          border: "none", // Elimina cualquier borde residual
          background: "none", // Elimina cualquier fondo residual
          boxShadow: "none", // Elimina sombras (si las hay)
        }}
        icon={
          isFav ? (
            <HeartFilled
              style={{ color: "rgba(246, 9, 9, 0.8)", fontSize: "20px" }}
            />
          ) : (
            <HeartOutlined style={{ color: "grey", fontSize: "20px" }} />
          )
        }
      />
    </Tooltip>
  );
};

export default FavButton;
