import axios from "axios";

import { API_CENTER_URL, trailing_key } from "../constants/index";
import { clearStorageByKey } from "./storage-service";
import { createCenterLatestPositions } from "../dao/center-dao";

/**
 * No se usa
 * @param {*} idCenter 
 * @param {*} coord 
 * @returns 
 */
export const getCenteDistanceFromCoord = (idCenter, coord) => {
  if (coord && idCenter) {
    const uri = API_CENTER_URL + 
      `/center/${idCenter}/distance-to/${coord[0]}/${coord[1]}` + trailing_key;
    axios
      .get(uri)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
        return [];
      });
  } else {
    return [];
  }
};

/**
 * No se usa
 * @param {*} coord 
 * @param {*} radius 
 * @param {*} denom 
 * @returns 
 */
export const getMarkersFromCoord = (coord, radius, denom) => {
  if (coord && radius && denom) {
    const uri =
      `/${radius}/${coord[0]}/${coord[1]}/denom/${denom}` + trailing_key;
    axios
      .get(uri)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
        return [];
      });
  } else {
    return [];
  }
};

/**
 * Llamada desde lista principal de centros para cargar las últimas plazas 
 */
export const getLatestPlazas = () => {
  const uri = API_CENTER_URL + "/plazas/latest" + trailing_key;
  axios
    .get(uri)
    .then((response) => {
      // limpio cache: TODO: hacer más eficiente
      clearStorageByKey("latest-pos-");
      // añado a cache
      for (let i = 0; i < response.data.length; i++) {
        const resultado = response.data[i];
        const puesto_code = resultado?.puesto?.split("-")[0].trim();
        const key = `latest-pos-${resultado.centro}-${puesto_code}`;
        // creo una nueva entrada
        const data = createCenterLatestPositions(
          resultado.centro,
          resultado.num_plazas,
          resultado.puesto,
          resultado.tipo,
          resultado.participacion,
          resultado.fecha_prevista_cese,
          resultado.created,
          resultado.obsrvaciones
        );
        localStorage.setItem(key, JSON.stringify(data));
      }
    })
    .catch((error) => {
      console.error(error);
    });
};

/**
 * Devuelve las plazas sipri (todas) para estadistics y fecha ultima convocatoria en home
 * @returns
 */
export const getLastPlazas = async () => {
  const uri = API_CENTER_URL + "/plazas/last" + trailing_key;
  let positions = [];
  try {
    const response = await axios.get(uri);
    for (let i = 0; i < response.data.length; i++) {
      const resultado = response.data[i];
      const data = createCenterLatestPositions(
        resultado.centro,
        resultado.num_plazas,
        resultado.puesto,
        resultado.tipo,
        resultado.participacion,
        resultado.fecha_prevista_cese,
        resultado.created,
        resultado.observaciones,
        resultado.centro_latitude,
        resultado.centro_longitude,
        resultado.centro_name,
        resultado.centro_denom,
        resultado.centro_city,
        resultado.centro_province,
        resultado.centro_code,
      );
      positions.push(data);
    }
    return JSON.stringify(positions);
  } catch (error) {
    throw error;
  }
};

/**
 * usado en center info
 * @param {*} code 
 * @returns 
 */
export const getCenterByCode = async (code) => {
  const uri = API_CENTER_URL + `/center/${code}` + trailing_key;
  try {
    const response = await axios.get(uri, {
      timeout: 10000, // Establece el timeout
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
