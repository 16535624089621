import React, { useState, useEffect } from "react";
import { List } from "antd";
import { FaTaxi } from "react-icons/fa";
import axios from "axios";
import { renderItem } from "../../pages/travel/travel-search";
import { api_key, API_TRAVELS_URL } from "../../constants";
import { buildQueryString } from "../../utils/utils";

const TravelsMunCenter = (props) => {
  const [travels, setTravels] = useState([]);

  const fetchDataTravels = async (munCodeOrig, munCodeDest, schedule) => {
    const params = [
      { key: "muncode", value: munCodeOrig },
      { key: "muncodedest", value: munCodeDest },
      { key: "schedule", value: schedule },
      { key: "api_key", value: api_key },
    ];
    const queryString = buildQueryString(params);
    const uri = `${API_TRAVELS_URL}/search-travels/?${queryString}`;
    try {
      const response = await axios.get(uri);
      if (response.data) {
        setTravels(response.data);
      }
    } catch (error) {
      console.error("Hubo un error recuperar los datos", error);
    }
  };

  useEffect(() => {
    fetchDataTravels(props.munCodeOrig, props.munCodeDest, props.schedule);
  }, [props.munCodeOrig, props.munCodeDest, props.schedule]);

  return (
    <>
      {!!travels && travels.length > 0 ? (
        <>
          <h4 className="mb-2">
            Opciones para compartir coche
          </h4>
          <List dataSource={travels} renderItem={renderItem} />
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default TravelsMunCenter;
