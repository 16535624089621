import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment/moment";
import "moment/locale/es"; // Importar el locale español
import { SearchOutlined } from "@ant-design/icons";
import { AutoComplete, Input, Card } from "antd";
import { Tag } from "antd-mobile";
import { isMobile } from "react-device-detect";
import { isAuth } from "../../services/auth-services";
import { API_CENTER_URL, trailing_key_param } from "../../constants";
import { getLastPlazas } from "../../services/center-services";
import { capitalize } from "../../utils/utils";
import andalucia from "../../assets/andalucia-white.svg";

function LatestPositions(props) {
  const [positions, setPositions] = useState([]);
  const [dateLast, setDateLast] = useState(null);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const pos = JSON.parse(await getLastPlazas());
        setPositions(pos);
        let fecha = moment(pos[0].date);
        fecha.locale("es"); // Establecer el idioma español
        setDateLast(fecha.format("LL"));
      } catch (error) {
        console.log("Error fetching data", error);
      }
    };

    fetchData();
  }, []);

  const handleSearch = async (value) => {
    if (value.length > 2) {
      try {
        const uri =
          API_CENTER_URL +
          `/positions/positions/?name=${value}${trailing_key_param}`;
        const response = await axios.get(uri);
        if (response.data.length > 0) {
          const options = response.data.map((record) => ({
            value: record.name,
            label: (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <span>{capitalize(record.name)}</span>
                <span className="coords d-none">{record.name}</span>
                <span className="code d-none">{record.code}</span>
              </div>
            ),
          }));
          setOptions(options);
        }
      } catch (error) {
        setOptions([]);
        console.error("Error fetching positions:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const onSelect = async (value, item) => {
    if (value === undefined) {
      return;
    }
    const children = item.label.props.children;
    const positionName = children[1].props.children;
    const positionCode = children[2].props.children;

    let pathName = encodeURIComponent(capitalize(positionName));
    let pathCode = positionCode;
    let path = `/centros/puesto/${pathCode}/${pathName}`;
    if (!!isAuth()) path = `/centros/cercanos/puesto/${pathCode}/${pathName}`;

    navigate(path);
  };

  const onClear = () => {};

  return (
    <>
      {!!positions && positions.length > 0 && (
        <Card
          className="col-md-8 offset-md-2 col-xs-12 text-white"
          style={{
            fontSize: "1rem",
            backgroundColor: "#7EBF8E",
          }}
        >
          <div className={isMobile ? "p-2" : "row p-4"}>
            <div
              className="col-xs-12 col-md-7 text-center"
              style={{
                fontSize: "1rem",
              }}
            >
              <h4>¿Buscas vacantes en Andalucía?</h4>
              <div className="text-center mt-2">
                <strong>Encuentra vacantes cerca de ti</strong>
                <br />
                <AutoComplete
                  options={options}
                  onSelect={onSelect}
                  onSearch={handleSearch}
                  allowClear={true}
                  onClear={onClear}
                  className="px-2 my-2"
                >
                  <Input
                    prefix={<SearchOutlined />}
                    placeholder={"Escribe la especialidad"}
                    size="large"
                    style={{ width: "280px" }}
                  />
                </AutoComplete>{" "}
                <br />
                <br />
                <a
                  href="/vacantes/1/andalucia"
                  className="underlined text-white"
                >
                  o explora las últimas vacantes publicadas en SIPRI Andalucía
                </a>
              </div>
            </div>
            <div className="col-xs-12 col-md-5 text-center">
              <img
                src={andalucia}
                alt="Andalucía"
                style={{ height: "100px" }}
                className="ms-4 mt-1"
              />
              <br />
              <br /> El {dateLast} se han publicado{" "}
              <Tag color="rgba(246, 9, 9, 0.8)">
                <span style={{ fontSize: "1rem" }}>
                  {positions?.length + " plazas"}
                </span>
              </Tag>{" "}
              en{" "}
              <a href="/vacantes/1/andalucia" className="underlined text-white">
                SIPRI Andalucía.
              </a>
            </div>
            <div className="col-xs-12 col-md-8"></div>
          </div>
        </Card>
      )}
    </>
  );
}

export default LatestPositions;
