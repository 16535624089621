import axios from "axios";
import {
  API_PORTAL_URL,
  API_COUNTRY_URL,
  trailing_key,
} from "../constants/index";

/**
 *
 * @param {*} key
 */
export const getChunkByKey = async (key) => {
  const uri = API_PORTAL_URL + `/chunks/${key}` + trailing_key;
  try {
    const response = await axios.get(uri, {
      timeout: 5000,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 *
 * @param {*} lat
 * @param {*} lon
 * @returns
 */
export const getMunByLatLon = async (lat, lon) => {
  const uri = API_COUNTRY_URL + `/municipality/${lat}/${lon}` + trailing_key;
  try {
    const response = await axios.get(uri, {
      timeout: 5000,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * 
 * @param {*} code 
 * @returns 
 */
export const getMunByCode = async (code) => {
  const uri = API_COUNTRY_URL + `/municipality/${code}` + trailing_key;

  try {
    const response = await axios.get(uri, {
      timeout: 5000,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
