import { useState } from "react";
import { useNavigate } from "react-router-dom";

import axios from "axios";
import { Form, Input, Button, notification } from "antd";
import { jwtDecode } from "jwt-decode";

import { API_AUTH_URL, trailing_key } from "../constants";
import { clearStorageByKey } from "../services/storage-service";
import {
  AUTH_TOKEN,
  REFRESH_TOKEN,
  USER_ID,
  USER_EMAIL,
  USER_FIRST_NAME
} from "../services/auth-services";

const LoginForm = (props) => {
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    setLoading(true);
    const uri = API_AUTH_URL + "/token" + trailing_key;
    try {
      const response = await axios.post(
        uri,
        {
          email: values.email,
          password: values.password,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          // withCredentials: true,  // da error CORS
        }
      );

      clearStorageByKey(AUTH_TOKEN);
      clearStorageByKey(REFRESH_TOKEN);
      // viene codificado en el auth_token
      const decodedToken = jwtDecode(response.data.access);
      localStorage.setItem(AUTH_TOKEN, response.data.access);
      localStorage.setItem(REFRESH_TOKEN, response.data.refresh);
      localStorage.setItem(USER_ID, decodedToken.user_id);
      localStorage.setItem(USER_EMAIL, decodedToken.user_email);
      localStorage.setItem(USER_FIRST_NAME, decodedToken.name);     
      localStorage.setItem("social_auth", false);

      // guarda el access token - bearer- en la config de axios. NO SE SI FUNCIONA
      /*axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${response.data.access}`;*/
      //axios.defaults.headers.common["Authorization"] = `Bearer ${response.data}`;
      notification.success({
        message: "Sesión iniciada correctamente.",
      });
      navigate(props.from ? props.from : "/");
    } catch (error) {
      notification.warning({
        message: "Correo electrónico o contraseña incorrectos",
      });
    }

    setLoading(false);
  };

  const onFinishFailed = (errorInfo) => {
    notification.warning({
      message: "Por favor escribe tu correo electrónico y contraseña",
      description: errorInfo,
    });
  };

  return (
    <Form
      name="login-form"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item
        name="email"
        rules={[
          {
            required: true,
            message: "Por favor escribe tu correo electrónico",
          },
        ]}
      >
        <Input placeholder="Correo electrónico" />
      </Form.Item>

      <Form.Item
        name="password"
        rules={[{ required: true, message: "Por favor escribe tu contraseña" }]}
      >
        <Input.Password placeholder="Contraseña" />
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          loading={loading}
          className="pull-right"
        >
          Entrar
        </Button>
      </Form.Item>
    </Form>
  );
};

export default LoginForm;
